import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { handleTokenError } from "../../../components/src/Utility";

interface SearchResultAttributes {
  cpt_code: string;
  procedure_code_description: string;
  account_id: number;
  user_time: string;
  user_weekday: string;
}

interface SearchResult {
  id: string;
  type: string;
  attributes: SearchResultAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  clickedId: number;
  token: string;
  searchResultData: SearchResult[];
  totalResults: number;
  currentPage: number;
  itemsPerPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SearchHistoryControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSearchHistoryData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      clickedId: 0,
      token: "",
      searchResultData: [],
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      handleTokenError(responseJson);

      if (
        responseJson &&
        responseJson.error &&
        apiRequestCallId === this.apiGetSearchHistoryData

      ) {
        this.setState({ searchResultData: [], totalResults: 0 });
      }

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiGetSearchHistoryData
      ) {
        this.setState({ searchResultData: responseJson.data, totalResults: responseJson.meta.total_record });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    return Promise.resolve();
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getSearchHistoryData(token);
  };

  getSearchHistoryData = (token : string) => {
    const { currentPage, itemsPerPage } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSearchHistoryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/search/search_history?page=${currentPage}&per_page=${itemsPerPage}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleBoxClick = (id: number) => {
    this.setState({ clickedId: id });
  };

  handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getSearchHistoryData(this.state.token);
    });
  };
  // Customizable Area End
}
