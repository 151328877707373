import React from "react";

// Customizable Area Start
import { imgCancle, imgCheck, imgCheckGrey, imgLogin, imgLogo } from "./assets";
import { Box, Grid, Typography, TextField, FormControl, FormLabel, Button, InputAdornment, IconButton } from "@mui/material";
import {Visibility as VisibilityIcon , VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { validatePassword, getRequirements, getErrorAndHelperText, getRequirementImageKey } from "../../../components/src/Utility";
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

interface FormErrorTouched {
  [key: string]: boolean;
}
interface FormError {
  [key: string]: string;
}
// Customizable Area End

import ResetPasswordControllerWeb, {
  Props,
  configJSON,
} from "./ResetPasswordController.web";

export default class ResetPassword extends ResetPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getResetPasswordInitialValues() {
    return {
      password:"",
      confirmPassword:"",
    };
  }

  passwordValidationSchemaForResetPassword = Yup.string()
  .required('Password is required')
  .test('password-requirements', 'Password does not meet the requirements', validatePassword);

  validationSchema = Yup.object().shape({
    password: this.passwordValidationSchemaForResetPassword,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Please enter the password again')
      .required('Confirm Password is required'),
  });

  images = {
    imgCheck: imgCheck,
    imgCheckGrey: imgCheckGrey,
    imgCancel: imgCancle,
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const initialValues = this.getResetPasswordInitialValues();
    const { showConfirmPassword, confirmPasswordError, fieldBlurred, showPassword } = this.state;
    const requirements = getRequirements();
    return (
      <Box sx={styles.resetPasswordMainContainer}>
        <Grid container spacing={2} sx={styles.mainGridResetPassword}>
          <Grid
            sm={12}
            xs={12}
            md={6}
            item
            sx={styles.imageContainerResetPassword}
            lg={6}
            xl={6}
          >
            <Box sx={styles.imageWrapperResetPassword}>
              <img
                src={imgLogin}
                style={styles.imageResetPassword}
              />
              <Box sx={styles.logoResetPassword}>
                <img src={imgLogo} alt="LogoResetPassword" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            sx={styles.secondGridResetPassword}
            xl={6}
            lg={6}
            xs={12}
          >
            <Box sx={styles.formContainerResetPassword}>
              <Typography sx={styles.resetPasswordText}>
                Reset Password
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({
                  errors,
                  touched,
                  isValid,
                  values,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form style={styles.resetPasswordForm}>
                    <FormControl sx={styles.resetPasswordFormControlPassword}>
                      <FormLabel sx={styles.labelTextResetPassword}>
                        Password
                      </FormLabel>
                      <Box>
                        <Field
                          as={TextField}
                          name="password"
                          variant="outlined"
                          placeholder="Enter Password"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          value={values.password}
                          onChange={handleChange("password")}
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            this.handleBlur(values.password);
                          }}
                          sx={styles.fieldStyleResetPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="toggle-password-visibility"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon sx={styles.iconColor} />
                                  ) : (
                                    <VisibilityOffIcon sx={styles.iconColor} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            getErrorAndHelperText(
                              "password",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            getErrorAndHelperText(
                              "password",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Box>
                      <Box>
                        {values.password && !isValid && (
                          <>
                            {requirements.map((requirement, index) => {
                              const imageKey = getRequirementImageKey(
                                requirement,
                                values.password,
                                fieldBlurred
                              );
                              const src = this.images[imageKey];
                              const alt = `${imageKey
                                .replace("img", "")
                                .toLowerCase()} icon`;
                              return (
                                <Box
                                  data-test-id="password-reqirements"
                                  key={index}
                                  sx={styles.passwordRequirementBox}
                                >
                                  <img src={src} alt={alt} />
                                  <Typography sx={styles.errorText}>
                                    {requirement.label}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </Box>
                    </FormControl>
                    <FormControl sx={styles.resetPasswordFormControlPassword}>
                      <FormLabel sx={styles.labelTextResetPassword}>
                        Confirm password
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="confirmPassword"
                        variant="outlined"
                        placeholder="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth
                        sx={styles.fieldStyleResetPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="toggle-password-visibility"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityIcon sx={styles.iconColor} />
                                ) : (
                                  <VisibilityOffIcon sx={styles.iconColor} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          getErrorAndHelperText(
                            "confirmPassword",
                            errors as FormError,
                            touched as FormErrorTouched,
                            confirmPasswordError
                          ).isError
                        }
                        helperText={
                          getErrorAndHelperText(
                            "confirmPassword",
                            errors as FormError,
                            touched as FormErrorTouched,
                            confirmPasswordError
                          ).helperText
                        }
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={styles.continueButton}
                      fullWidth
                      disabled={!isValid}
                    >
                      {configJSON.continue}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  passwordRequirementBox:{
    display:'flex',
    alignItems:'center',
    padding:'5px 0px',
  },
  successfullyResetPasswordBox:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    padding:'0px 40px',
  },
  successfullyResetPasswordText:{
    fontFamily:"Tiempos Headline",
    fontWeight:"700",
    fontSize:"30px",
    lineHeight:"40px",
    marginTop: '30px',
    marginBottom: '30px',
    color: '#1C1917',
    textAlign: 'center',
  },
  errorText:{
    color:'#0F172A',
    marginLeft: '7px',
    fontSize:"12px",
    lineHeight:"18px"
  },
  continueButton: {
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  iconColor: {
    color: '#94A3B8',
  },
  fieldStyleResetPassword:{
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft:"5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: "8px",
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  resetPasswordFormControlPassword:{
    width: '100%',
    marginBottom: '20px',
  },
  labelTextResetPassword:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  resetPasswordForm:{
    width: '100%',
  },
  resetPasswordText:{
    fontFamily: "Tiempos Headline",
    fontSize:"30px",
    lineHeight:"40px",
    color: "#1C1917",
    marginBottom: '20px',
    fontWeight: '700',
  },
  formContainerResetPassword: {
    alignItems: 'center',
    height: '100%',
    padding: '0 120px',
    "@media (max-width:699px)": {
      padding: '0px',
    },
    "@media (max-width:1199px)": {
      padding: '0 100px',
    },
    "@media (max-width:799px)": {
      padding: '0px 20px',
    },
    "@media (max-width:1099px)": {
      padding: '0 80px',
    },
    "@media (max-width:899px)": {
      marginTop: '20px',
      padding: '0px 40px',
    },
    "@media (max-width:999px)": {
      padding: '0 60px',
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logoResetPassword: {
    height: '50px',
    position: 'absolute',
    width: '50px',
    left: '25px',
    top: '25px',
  },
  imageResetPassword: {
    width: '100%',
    height: '100%',
  },
  imageWrapperResetPassword: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  resetPasswordMainContainer: {
    "@media (max-width:899px)": {
      overflow: "visible",
      padding: "34px",
      height: "100%",
    },
    margin: "34px",
    overflow: "hidden",
    height: "100%",
  },
  mainGridResetPassword: {
    marginLeft: 0,
    width: "100%",
    height: "100%",
    marginTop: 0,
  },
  secondGridResetPassword:{
    paddingTop:'0 !important',
    height:"100%",
    paddingLeft:'0 !important',
  },
  imageContainerResetPassword: {
    position: 'relative',
    width: '100%',
    paddingTop:'0 !important',
    paddingLeft:'0 !important',
    height: '100%',
  },
};
// Customizable Area End
