import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, TextField, FormControl, FormLabel, Checkbox, FormControlLabel, Button } from "@mui/material";
import { imgLogin, imgLogo } from "./assets";
import { Formik, Form, Field, ErrorMessage, FormikTouched, FormikErrors } from 'formik';
import * as Yup from 'yup';

interface FieldProps<T> {
  name: keyof T;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  customError?: string;
}
// Customizable Area End

import AccountRegistrationControllerWeb, {
  Props,
  configJSON,
} from "./AccountRegistrationController.web";

export default class AccountRegistration extends AccountRegistrationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFieldProps<T>({ name, touched, errors, customError }: FieldProps<T>) {
    const isTouched = touched[name];
    const error = errors[name];
    const hasCustomError = Boolean(customError);
  
    return {
      error: (isTouched && Boolean(error)) || hasCustomError,
      helperText: (isTouched && error) ? <ErrorMessage name={String(name)} /> : customError
    };
  }

  getInitialValues() {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      zipCode: this.state.zipCode,
      phoneNo: this.state.phoneNo,
      acceptCondition: this.state.acceptCondition,
    };
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
    .required("First Name is required")
    .matches(/^[a-zA-Z ]+$/, "First Name should only contain letters"),
    lastName: Yup.string().required("Last Name is required")
    .matches(/^[a-zA-Z ]+$/, "Last Name should only contain letters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
      zipCode: Yup.string()
      .required("Zip Code is required")
      .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits"),
    phoneNo: Yup.string()
      .required("Phone No. is required")
      .matches(/^\d{10}$/, "Phone No. must be exactly 10 digits"),
    acceptCondition: Yup.boolean()
      .oneOf(
        [true],
        "Agree to Privacy Policy & Terms to Sign up."
      )
      .required("Agree to Privacy Policy & Terms to Sign up."),
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const initialValues = this.getInitialValues();
    const { emailError , phoneNumberError } = this.state;
    return (
      <Box sx={styles.signupMainContainer}>
        <Grid container spacing={2} sx={styles.mainGridSignup}>
          <Grid
            item
            sx={styles.imageContainerSignup}
            lg={6}
            xl={6}
            sm={12}
            xs={12}
            md={6}
          >
            <Box sx={styles.imageWrapperSignup}>
              <img
                src={imgLogin}
                alt={configJSON.loginAlt}
                style={styles.imageSignup}
              />
              <Box sx={styles.logoSignup}>
                <img src={imgLogo} alt="LogoSignUp" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xl={6}
            lg={6}
            xs={12}
            sx={styles.secondGridSignup}
          >
            <Box sx={styles.formContainerSignup}>
              <Typography sx={styles.signupText}>
                {configJSON.labelTitle}
              </Typography>
              <Formik
                key={`${initialValues.firstName}-${initialValues.lastName}-${initialValues.email}-${initialValues.zipCode}-${initialValues.phoneNo}-${initialValues.acceptCondition}`}
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ values, errors, touched, isValid }) => (
                  <Form style={styles.signupForm}>
                    <FormControl sx={styles.signupFormControl}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.labelFirstName}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="firstName"
                        variant="outlined"
                        type="firstName"
                        placeholder="Enter First Name"
                        fullWidth
                        sx={styles.fieldStyle}
                        {...this.getFieldProps({
                          name: "firstName",
                          touched,
                          errors,
                        })}
                      />
                    </FormControl>
                    <FormControl sx={styles.signupFormControl}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.lastName}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="lastName"
                        variant="outlined"
                        type="lastName"
                        placeholder="Enter Last Name"
                        fullWidth
                        sx={styles.fieldStyle}
                        {...this.getFieldProps({
                          name: "lastName",
                          touched,
                          errors,
                        })}
                      />
                    </FormControl>
                    <FormControl sx={styles.signupFormControl}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.labelEmail}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="email"
                        variant="outlined"
                        type="email"
                        placeholder="Enter Email"
                        fullWidth
                        sx={styles.fieldStyle}
                        {...this.getFieldProps({
                          name: "email",
                          touched,
                          errors,
                          customError: emailError,
                        })}
                      />
                    </FormControl>
                    <FormControl sx={styles.signupFormControl}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.zipCode}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="zipCode"
                        variant="outlined"
                        type="zipCode"
                        placeholder="Enter Zip Code"
                        fullWidth
                        sx={styles.fieldStyle}
                        {...this.getFieldProps({
                          name: "zipCode",
                          touched,
                          errors,
                        })}
                      />
                    </FormControl>
                    <FormControl sx={styles.signupFormControlLast}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.phoneNumber}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="phoneNo"
                        variant="outlined"
                        type="phoneNo"
                        placeholder="Enter Phone no"
                        fullWidth
                        sx={styles.fieldStyle}
                        {...this.getFieldProps({
                          name: "phoneNo",
                          touched,
                          errors,
                          customError: phoneNumberError,
                        })}
                      />
                    </FormControl>
                    <Box sx={styles.boxWidth}>
                      <Box sx={styles.checkBoxMainBox}>
                        <FormControlLabel
                          data-test-id="form-control-label"
                          control={
                            <Field
                              as={Checkbox}
                              name="acceptCondition"
                              sx={styles.checkBox}
                            />
                          }
                          label={
                            <span style={styles.checkboxText}>
                              I agree with{" "}
                              <span
                                style={styles.link}
                                data-test-id="privacy-policy"
                                onClick={() =>
                                  this.handleNavigationToPrivacy(values)
                                }
                              >
                                Privacy Policy
                              </span>{" "}
                              and{" "}
                              <span
                                style={styles.link}
                                data-test-id="terms-condition"
                                onClick={() =>
                                  this.handleNavigationToTermsAndConditions(
                                    values
                                  )
                                }
                              >
                                Terms and Conditions
                              </span>
                            </span>
                          }
                        />
                      </Box>
                      {errors.acceptCondition && touched.acceptCondition ? (
                        <Box sx={styles.error}>{errors.acceptCondition}</Box>
                      ) : null}
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={styles.signupButton}
                      disabled={!isValid}
                      fullWidth
                    >
                      {configJSON.createAccount}
                    </Button>
                    <Box sx={styles.boxCenter}>
                      <Typography sx={styles.signupTextLast}>
                        {configJSON.alreadyHaveAnAccount}{" "}
                        <Typography
                          component="a"
                          data-test-id="login-text"
                          onClick={() => this.handleLoginNavigation()}
                          sx={styles.signupLink}
                        >
                          {configJSON.login}
                        </Typography>
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  signupMainContainer: {
    margin:"34px",
    overflow: 'hidden',
    height: '100%',
    "@media (max-width:899px)": {
      overflow: 'visible',
      padding: '34px',
      height:'100%',
    },
  },
  logoSignup: {
    position: 'absolute',
    top: '25px',
    left: '25px',
    width: '50px',
    height: '50px',
  },
  signupForm:{
    overflow:"auto",
  },
  signupFormControl: {
    marginBottom: '15px',
    width: '100%',
  },
  signupFormControlLast: {
    width: '100%',
    marginBottom: '20px',
  },
  formContainerSignup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '0 120px',
    "@media (max-width:1199px)": {
      padding: '0 100px',
    },
    "@media (max-width:1099px)": {
      padding: '0 80px',
    },
    "@media (max-width:999px)": {
      padding: '0 60px',
    },
    "@media (max-width:899px)": {
      marginTop: '20px',
      padding: '0px 40px',
    },
    "@media (max-width:799px)": {
      padding: '0px 20px',
    },
    "@media (max-width:699px)": {
      padding: '0px',
    },
    justifyContent: 'center',
  },
  labelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"20px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  fieldStyle:{
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      padding: '10px 5px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "8px",
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  imageContainerSignup: {
    width: '100%',
    position: 'relative',
    height: '100%',
    paddingTop:'0 !important',
    paddingLeft:'0 !important',
  },
  signupButton:{
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      cursor:'pointer',
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  signupText:{
    fontSize:"30px",
    fontWeight: '700',
    fontFamily: "Tiempos Headline",
    lineHeight:"40px",
    marginBottom: '15px',
    color: "#1C1917",
  },
  checkBox:{
    '&.Mui-checked': {
      color: 'green',
    },
  },
  mainGridSignup:{
    height: '100%',
    width:"100%",
    marginTop: 0,
    marginLeft: 0,
  },
  secondGridSignup:{
    paddingTop:'0 !important',
    paddingLeft:'0 !important',
    height:"100%",
  },
  boxWidth:{
    width: '100%',
    marginBottom: '10px',
  },
  checkBoxMainBox:{
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  boxMarginBottom:{
    marginBottom: '10px',
  },
  checkboxText:{
    whiteSpace: "nowrap",
    fontSize:"14px",
    fontWeight:400,
    color:"#1E293B",
  },
  link:{
    textDecoration: "underline",
    cursor: "pointer",
  },
  error:{
    fontFamily: "Roboto",
    color: "red",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginBottom: 0,
  },
  imageWrapperSignup: {
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxCenter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'10px',
  },
  signupLink:{
    cursor:'pointer',
    color: "#1799CB",
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    textDecoration: 'none',
    '&:hover': {
      cursor:'pointer',
      color: "#1799CB",
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      textDecoration: 'none',
    },
  },
  signupTextLast:{
    color: "#0F172A",
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  imageSignup: {
    height: '100%',
    width: '100%',
  },
}
// Customizable Area End
