import React from "react";

// Customizable Area Start
import { Box, Typography, Divider, Pagination } from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import SearchHistoryControllerWeb, {
  Props,
  configJSON,
} from "./SearchHistoryController.web";

export default class SearchHistory extends SearchHistoryControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: configJSON.navigationMenuId,
    isLandingPage: false,
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { clickedId, searchResultData, totalResults, currentPage, itemsPerPage } = this.state;
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            <Box sx={styles.searchHistory}>
              {searchResultData.map((searchHistory) => (
                <Box
                  sx={
                    clickedId === +searchHistory.id
                      ? styles.innerBoxClicked
                      : styles.innerBox
                  }
                  key={searchHistory.id}
                  data-test-id={"search-history"}
                  onClick={() => this.handleBoxClick(+searchHistory.id)}
                >
                  <Typography sx={styles.searchTitle}>
                    {searchHistory.attributes.procedure_code_description}
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"30px"}
                  >
                    <Typography sx={styles.cptcodeText}>
                      CPT CODE- {searchHistory.attributes.cpt_code}
                    </Typography>
                    <Typography variant="h3" sx={styles.searchDate}>
                      {searchHistory.attributes.user_weekday} ,{" "}
                      {searchHistory.attributes.user_time}
                    </Typography>
                  </Box>
                  <Divider sx={styles.dividerColor} />
                </Box>
              ))}
              {totalResults > itemsPerPage && (
                <Box sx={styles.paginationBox}>
                  <Pagination
                    data-test-id="paginations"
                    count={Math.ceil(totalResults / itemsPerPage)}
                    page={currentPage}
                    onChange={this.handlePageChange}
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  paginationBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
  },
  innerBox: {
    cursor: "pointer",
    padding: "30px 60px 0px",
    backgroundColor: "#ECFAFF",
  },
  innerBoxClicked: {
    padding: "30px 60px 0px",
    cursor: "pointer",
    backgroundColor: "#fff !important",
  },
  dividerColor: {
    backgroundColor: "#D4D2E3",
  },
  cptcodeText: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#1E293B",
  },
  searchDate: {
    fontSize: "20px",
    fontWeight: "300",
    color: "#64748B",
  },
  searchDescription: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#9795B5",
  },
  searchHistory: {
    padding: "28px 0px 60px",
  },
  searchTitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#1E293B",
    marginBottom: "6px",
  },
  container: {
    padding: "40px",
  },
  innerContainer: {
    borderRadius: "16px",
    backgroundColor: "#ECFAFF",
  },
};
// Customizable Area End
