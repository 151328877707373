// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import CommingSoon from '../../components/src/ComingSoon';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import CptCode from "../../blocks/landingpage/src/CptCode.web";
import LandingPagePayment from "../../blocks/landingpage/src/LandingPagePayment.web";
import UserProfile from "../../blocks/educational-user-profile/src/UserProfile.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import AccountRegistration from "../../blocks/email-account-registration/src/AccountRegistration.web";
import CreatePassword from "../../blocks/email-account-registration/src/CreatePassword.web";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Contactus from "../../blocks/contactus/src/Contactus";
import TakeHelp from "../../blocks/contactus/src/TakeHelp.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import SearchHistory from "../../blocks/advancedsearch/src/SearchHistory.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPasswordBlock from "../../blocks/forgot-password/src/ForgotPasswordBlock.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Stripegatewayapifrontend2 from "../../blocks/stripegatewayapifrontend2/src/Stripegatewayapifrontend2";
import TermsAndConditions from "../../blocks/termsconditions/src/TermsAndConditions.web";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfdatabaseupdateadd from "../../blocks/cfdatabaseupdateadd/src/Cfdatabaseupdateadd";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage",exact:true},
SearchHistory:{
  component:SearchHistory,
 path:"/SearchHistory"},
 ContactUs:{
  component:TakeHelp,
 path:"/Contact-Us"},
 Profile:{
  component:UserProfile,
 path:"/Profile"},
CptCode:{
  component:CptCode,
 path:"/LandingPage/code",exact:true},
SubscriberCost:{
  component:LandingPagePayment,
 path:"/LandingPage/SubscriberCost",exact:true},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLogin:{
  component:EmailAccountLogin,
 path:"/Login"},
 AccountRegistration:{
  component:AccountRegistration,
 path:"/Signup"},
 CreatePassword:{
  component:CreatePassword,
 path:"/CreatePassword"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPasswordBlock,
path:"/ForgotPassword"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Stripegatewayapifrontend2:{
 component:Stripegatewayapifrontend2,
path:"/Stripegatewayapifrontend2"},
TermsConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PrivacyPolicy:{
  component:PrivacyPolicy,
 path:"/PrivacyPolicy"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfdatabaseupdateadd:{
 component:Cfdatabaseupdateadd,
path:"/Cfdatabaseupdateadd"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <ToastContainer/>
        <View style={{ height: '100vh', width: '100%' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;