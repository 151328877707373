import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";

interface LoginValues{
  email: string;
  password: string;
  rememberMe: boolean;
}

interface ResponseJson {
  errors?: Array<{ [key: string]: string }>;
  meta?: {
    token: string | undefined;
    refresh_token: string | undefined;
    id: number | undefined;
    current_plan: string | undefined;
    free_plan_active: boolean | undefined;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean
  email: string;
  password: string;
  rememberMe: boolean;
  emailError:string;
  passwordError:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CptCodeControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      showPassword: false,
      email:"",
      password:"",
      rememberMe: false,
      emailError: "",
      passwordError: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiEmailLoginCallId) {
        if (responseJson.errors) {
          this.handleLoginError(responseJson);
        } else {
          this.handleLoginSucess(responseJson);
        }
      }      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkCredentialsFromLocalStorage();
    return Promise.resolve();
  }

  checkCredentialsFromLocalStorage = async () => {
    const userCreds = await getStorageData("userCreds",true);
    if (userCreds) {
      this.setState({
        email: userCreds.email,
        password: userCreds.password,
      });
    }
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleSubmit = (values: LoginValues) => {
    this.handleLogin(values);
    this.setState({email: values.email, password: values.password, rememberMe: values.rememberMe})
  };

  handleLogin = (values: LoginValues) => {
    const attrs = {
      email: values.email,
      password: values.password,
    };
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleLoginError = (responseJson: ResponseJson) => {
    this.setState({
      emailError: "",
      passwordError: "",
    });
    if (responseJson?.errors) {
      const errorMessage = responseJson?.errors[0].failed_login;
      if (errorMessage === configJSON.emailError) {
        this.setState({ emailError: errorMessage });
      } else {
        this.setState({ passwordError: errorMessage });
      }
    }
  };

  handleLoginSucess = (responseJson: ResponseJson) => {
    this.saveLoggedInUserData(responseJson);
    this.handleNavigationToLandingPage();
    this.saveUserCredToLocalStorage(responseJson);
    setStorageData("authToken", responseJson?.meta?.token!);
    setStorageData("current_plan",responseJson?.meta?.current_plan);
    setStorageData(
      "userInfo",
      JSON.stringify({
        id: responseJson?.meta?.id!,
        token: responseJson?.meta?.token!,
        refresh_token: responseJson?.meta?.refresh_token!,
      })
    );
  };

  saveUserCredToLocalStorage = (responseJson: ResponseJson) => {
    if (responseJson && responseJson?.meta?.token && this.state.rememberMe) {
      setStorageData(
        "userCreds",
        JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        })
      );
    }
    else{
      removeStorageData("userCreds");
      this.setState({email: "", password: "", rememberMe: false});
    }
  }

  handleNavigationToLandingPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  saveLoggedInUserData(responseJson: ResponseJson) {
    if (responseJson && responseJson?.meta?.token) {
      const message: Message = new Message(
        getName(MessageEnum.SessionSaveMessage)
      );

      message.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      message.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson?.meta?.token
      );
      this.send(message);
    }
    return true;
  }

  handleForgotPasswordNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleSignupNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountRegistration");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  onEmailChange = () => {
    this.setState({ emailError: '' });
  };

  onPasswordChange = () => {
    this.setState({ passwordError: '' });
  };
  // Customizable Area End
}
