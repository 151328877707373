Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.deleteAccountEndPoint = 'account_block/accounts/delete_account';
exports.premium = "Premium";
exports.getPlanDataEndPoint = "bx_block_plan/plans";
exports.switchApiEndPoint = "bx_block_plan/plans/switch_plan";
exports.getAddressAndCardDetailsEndPoint = "bx_block_settings/settings/get_stripe_detail";
exports.updateCardAndAddressDetailsEndPoint = "bx_block_settings/settings/update_stripe_detail";
exports.addCardDetailsEndPoint = "bx_block_settings/settings/create_stripe_detail";
exports.okay = "Okay";
exports.publishment = "pk_test_51PRC7a02h0HQpyC0en3A953qYtevw7gxNcFlUeipDkE1JtBHjom8K2izTgU5DF0mVTmzRS4S551IRgo7i1pOW4Xc00Fl9PFB7n";
exports.stripePaymentEndPoint = "bx_block_stripe_integration/payment_intents/create_checkout_session";
exports.getRemainingCountOfPlan = "bx_block_stripe_integration/payment_intents/cpt_count";
exports.cancleCurrentPlan = "bx_block_stripe_integration/payment_intents/cancel_subscription";
exports.freePlanPurchaseAgain = "bx_block_plan/plans/active_free_plan";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End