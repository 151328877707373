import React from "react";
import { removeStorageData } from "../../framework/src/Utilities";
import { toast } from "react-toastify";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}

interface ErrorResponse {
  errors: {
    token: string;
  }[];
}


interface Requirement {
  label: string;
  test: (password: string) => boolean;
}

export const getErrorAndHelperText = (
  field: string,
  errors: FormError,
  touched: FormErrorTouched,
  customError?: string
) => {
  const isTouched = touched[field];
  const error = errors[field];
  const hasCustomError = Boolean(customError);
  const isError = (isTouched && Boolean(error)) || hasCustomError;
  const helperText = isTouched && error ? errors[field] : customError;
  if (error === "Password does not meet the requirements") {
    return { isError: false, helperText: "" };
  }
  return { isError, helperText };
};

export const getRequirements = (): Requirement[] => [
  {
    label: "At least one capital letter",
    test: (password: string) => /[A-Z]/.test(password),
  },
  {
    label: "At least one lowercase letter",
    test: (password: string) => /[a-z]/.test(password),
  },
  {
    label: "At least one number",
    test: (password: string) => /\d/.test(password),
  },
  {
    label: "Minimum character length is 8 characters",
    test: (password: string) => password.length >= 8,
  },
];

export const validatePassword = (value: string) => {
  if (!value) return false;
  const requirements = getRequirements();
  return requirements.every((requirement) => requirement.test(value));
};

export const validateExpirationYear = (value: string) => {
  if (!value) return false;
  const year = value.split('/')[1];
  return /^\d{4}$/.test(year);
};

export const validateExpirationMonth = (value: string) => {
  if (!value) return true;
  const month = value.substring(0, 2);
  return parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12;
};

export const getRequirementImageKey = (requirement: Requirement, password: string, fieldBlurred: boolean) => {
  if (requirement.test(password)) {
    return "imgCheck";
  } else if (fieldBlurred) {
    return "imgCancel";
  } else {
    return "imgCheckGrey";
  }
}

export const fieldMappings: { [key: string]: string } = {
  phoneNo: "phone_number",
  zipCode: "zip_code",
  firstName: "first_name",
  lastName: "last_name",
  password: "user_pass"
};

export const handleTokenError = (responseJson: ErrorResponse) => {
  if (responseJson?.errors?.[0]?.token === "Token has Expired" || responseJson?.errors?.[0]?.token === "Invalid token") {
    toast.error("Your session has expired. Please log in again.");
    handleRemoveStorageData();
    window.location.pathname = "/Login";
  }
}

const handleRemoveStorageData = () => {
  removeStorageData("authToken");
  removeStorageData("userInfo");
  removeStorageData("selectedCptCodeData");
  removeStorageData("current_plan");
};