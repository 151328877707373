import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { handleTokenError } from "../../../components/src/Utility";

interface ZipCPTCodeData{
  zipCode: string;
  cptCode: string;
}

interface ChartData {
  average: number;
  percentile_fifty: number;
  percentile_ninety: number;
}

interface ProcedureCostResponse {
  error? : string;
  fifty_percentile_value: number;
  ninty_percentile_value: number;
  average: number;
  cpt_code_value: string;
  procedure_code_description: string;
  min_max_billed_charge: number[];
  chart_data: {
    [year: string]: ChartData;
  };
}

interface SimmiliarCptCodeRecord {
  cpt_code_value: string;
  procedure_description: string;
  average: number;
  fifty_percentile: number;
  ninty_percentile: number;
}

interface RemainCptCodeResponse {
  errors?: [];
  free_cpt_code: number;
  cpt_code: number | null;
  current_plan: string | null;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}

interface ResponseSubscriptionPlan{
  plans: SubscriptionPlan[]
}

interface CardAndAddressDetails {
  id: number;
  cardholder_name: string;
  card_number: string;
  expiration_date: string;
  cvv: number | string;
  country1: string;
  address_line: string;
  city: string;
  state: string;
  zip: string;
  country2: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface CardDetailsResponse {
  errors?: string;
  message: string;
  data: CardAndAddressDetails;
}

interface CardDetailsFormValues {
  cardholderName: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
}

interface StripeErrorResponse {
  code: string;
  doc_url: string;
  message: string;
  param: string; 
  request_log_url: string; 
  type: string;
}

interface ResponseDataForStripePayment {
  error?: "";
}

interface ResponseData {
  errors?: [];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  tabInnerValue: string;
  isTableModalOpen: boolean;
  token: string;
  cptCodeDataResponse: ProcedureCostResponse | null;
  currentPage: number;
  itemsPerPage: number;
  simmiliarCptCodeRecord : SimmiliarCptCodeRecord[];
  isPremiumPlan: boolean;
  totalRecords: number;
  premiumPlan: SubscriptionPlan;
  isCardDetailsFounded: boolean;
  cardDetailsFromGet: CardAndAddressDetails;
  planById: SubscriptionPlan;
  isCardDetailsModal: boolean;
  cardNumberError: string,
  expirationError: string,
  cvvError: string,
  cardDetails: CardDetailsFormValues;
  isPaymentDone: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPagePaymentControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCptCodeData: string = ""
  apiGetSimmiliarCptCodeData: string = ""
  apiGetRemainingCptCodeCount: string = ""
  apiGetPlanData: string = ""
  apiGetCardDetails: string = ""
  apiGetPlanDataById: string = ""
  apiStripePayment: string = ""
  apiUpdateCardDetailsCallId: string = ""
  apiAddCardDetails: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      tabValue: 0,
      tabInnerValue: "1",
      isTableModalOpen: false,
      token: "",
      cptCodeDataResponse: null,
      currentPage: 1,
      itemsPerPage: 5,
      simmiliarCptCodeRecord: [],
      isPremiumPlan: false,
      totalRecords: 0,
      premiumPlan: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      isCardDetailsFounded: false,
      cardDetailsFromGet: {
        id: 0,
        cardholder_name: "",
        card_number: "",
        expiration_date: "",
        cvv: "",
        country1: "",
        address_line: "",
        city: "",
        state: "",
        zip: "",
        country2: "",
        account_id: 0,
        created_at: "",
        updated_at: "",
      },
      planById: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      isCardDetailsModal: false,
      cardNumberError: "",
      expirationError: "",
      cvvError: "",
      cardDetails: {
        cardholderName: "",
        cardNumber: "",
        expiration: "",
        cvv: "",
      },
      isPaymentDone: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      handleTokenError(responseJson);

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiGetSimmiliarCptCodeData
      ) {
        this.setState({ simmiliarCptCodeRecord: responseJson.records, totalRecords: responseJson.meta.total_record });
      }

      switch (apiRequestCallId) {
        case this.apiGetCptCodeData:
          this.handleCPTCodeDataReturn(responseJson);
          break;

        case this.apiGetRemainingCptCodeCount:
          this.handlSuccessCptCodeCount(responseJson);
          break;

        case this.apiGetPlanData:
          this.handlePlanDataReturn(responseJson);
          break;

        case this.apiGetCardDetails:
          this.handleGetCardDetails(responseJson);
          break; 

        case this.apiGetPlanDataById:
          this.setState({ 
            planById: responseJson.plans
          });
          break;

        case this.apiStripePayment:
          this.handleStripePaymentSuccess(responseJson)
          break;

        case this.apiUpdateCardDetailsCallId:
          this.handleUpdateCardDetails(responseJson);
          break;
    
        case this.apiAddCardDetails:
          this.handleCardAddSuccess(responseJson);
          break;
    
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    this.checkZipcodeAndCPTCodeFromLocalStorage();
    this.getPlanData();
    return Promise.resolve();
  }

  handlePlanDataReturn = (responseJson : ResponseSubscriptionPlan) => {
    const { plans } = responseJson;
    const defaultPlan = {
      id: 0,
      name: "",
      duration: "",
      price: 0,
      details: null,
      created_at: "",
      updated_at: "",
      cpt_code: 0,
      plan1: false,
      plan2: false,
      features: "",
    }
    const premiumPlan = plans.find(plan => plan.name === 'premium') ?? defaultPlan;
    this.setState({ 
      premiumPlan
    });
  }

  handleGetCardDetails = (responseJson: CardDetailsResponse) => {
    if (responseJson.errors) {
      this.setState({ isCardDetailsFounded: false });
    } else {
      let formattedValue = "";
      if (responseJson.data && responseJson.data.card_number) {
        const inputValue = responseJson.data.card_number.replace(/\s+/g, "");
        formattedValue = (inputValue.match(/.{1,4}/g) as RegExpMatchArray).join(" ");
      }
      const formattedData = {
        ...responseJson.data,
        card_number: formattedValue,
      };
      this.setState({ isCardDetailsFounded: true, cardDetailsFromGet: formattedData });
    }
  }

  handleCPTCodeDataReturn = (responseJson : ProcedureCostResponse) => {
    if(responseJson.error){
      this.setState({ cptCodeDataResponse: null });
    }
    else{
      this.setState({ cptCodeDataResponse: responseJson });
    }
  };

  handleStripePaymentSuccess = (responseJson : ResponseDataForStripePayment) => {
    if (!responseJson.error) {
      this.handleAddOrUpdateCardDetails();
    }
  };

  handleUpdateCardDetails = (responseJson: ResponseData) => {
    if (!responseJson.errors) {
      if (!responseJson.errors) {
        this.handleCardDetailsModalClose();
        this.setState({ isPaymentDone: true });
      }
    }
  }

  handleCardAddSuccess = (responseJson : ResponseData) => {
    if (!responseJson.errors) {
      this.handleCardDetailsModalClose();
      this.setState({ isPaymentDone: true });
    }
  }

  checkZipcodeAndCPTCodeFromLocalStorage = async () => {
    const selectedCptCodeData = await getStorageData("selectedCptCodeData",true);
    this.getCPTCodeData(selectedCptCodeData)
  }

  handlSuccessCptCodeCount = (responseJson : RemainCptCodeResponse) => {
    if(!responseJson.errors){
      this.setState({
        isPremiumPlan: responseJson.current_plan === "premium",
      })
    }
  }

  getRemainingCountOfPlan = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRemainingCptCodeCount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRemainingCountOfPlan
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getRemainingCountOfPlan(token);
    this.getCardDetails(token);
  };

  getCardDetails = (token : string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCardDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressAndCardDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCPTCodeData = (selectedCptCodeData: ZipCPTCodeData) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCptCodeData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCPTCodeEndPoint}?zip_code=${selectedCptCodeData?.zipCode}&cpt_code=${selectedCptCodeData?.cptCode}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    this.setState({ tabValue: newValue });
  };

  handleInnerTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    this.setState({ tabInnerValue: newValue });
  };

  handleSimmiliarCptCodeButton = () => {
    this.getSimmiliarCptCodeData();
    this.setState({ isTableModalOpen: true });
  }

  getSimmiliarCptCodeData = async () => {
    const selectedCptCodeData = await getStorageData("selectedCptCodeData",true);
    const { currentPage, itemsPerPage, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSimmiliarCptCodeData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSimmiliarCptCodeDataEndPoint}?page=${currentPage}&per_page=${itemsPerPage}&cpt_code=${selectedCptCodeData?.cptCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleModalClose = () => {
    this.setState({ isTableModalOpen: false });
  };

  getChartData = (chart_data: { [year: string]: ChartData }) => {
    const years = Object.keys(chart_data);
    const averageValues = years.map(year => chart_data[year].average);
    const percentileFiftyValues = years.map(year => chart_data[year].percentile_fifty);
    const percentileNinetyValues = years.map(year => chart_data[year].percentile_ninety);

    const minYear = Math.min(...years.map(year => +year));

    return [
      ['Year', 'Average', { role: 'annotation', type: 'string' }, '50th Percentile', '90th Percentile'],
      ...years.map((year, index) => [
        +year, 
        averageValues[index], 
        year == minYear.toString() ? 'Min year Min year Min year Min Year Min Year min' : null,
        percentileFiftyValues[index], 
        percentileNinetyValues[index]
      ]),
    ];
  };

  handleNext = () => {
    const { currentPage, totalRecords, itemsPerPage } = this.state;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    if (currentPage < totalPages) {
      this.setState((prevState) => ({ currentPage: prevState.currentPage + 1 }), () => {
        this.getSimmiliarCptCodeData();
      });
    }
  };

  handlePrevious = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({ currentPage: prevState.currentPage - 1 }), () => {
        this.getSimmiliarCptCodeData();
      });
    }
  };

  getPlanData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlanDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOpenCardDetailsModel = (id : number) => {
    this.getPlanDataById(id);
    this.setState({ isCardDetailsModal: true });
  }

  getPlanDataById = (id : number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/plans/${id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCardDetailsModalClose = () => {
    this.setState({ 
      isCardDetailsModal: false,
     });
  };

  onCardNumberChange = () => {
    this.setState({ cardNumberError: '' });
  };

  onExpirationChange = () => {
    this.setState({ expirationError: '' });
  };

  onCvvChange = () => {
    this.setState({ cvvError: '' });
  };

  handleCardDetailsSubmit = (values : CardDetailsFormValues) => {
    const formattedValues = {
      ...values,
      cardNumber: values.cardNumber.replace(/\s+/g, ""),
    };
    this.createStripeToken(formattedValues);
  };

  createStripeToken = async (values: CardDetailsFormValues) => {
    const [expMonth, expYear] = values.expiration.split('/');
    const formData = new URLSearchParams();
    formData.append('card[number]', values.cardNumber);
    formData.append('card[exp_month]', expMonth);
    formData.append('card[exp_year]', expYear);
    formData.append('card[cvc]', values.cvv);
  
    await fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${configJSON.publishment}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.handleStripeTokenGenerationError(data.error);
        } else {
          this.setState({ cardDetails: values })
          this.handleStripePayment(data.id)
        }
      });
  };

  handleStripePayment = (stripeToken : string) => {
    const { planById, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      plan_id: `${planById.id}`,
      data:{
        token_card: stripeToken
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStripePayment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripePaymentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStripeTokenGenerationError = (error: StripeErrorResponse) => {
    switch (error.code) {
      case 'incorrect_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_expiry_month':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_expiry_year':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_cvc':
        this.setState({ cvvError : error.message });
        break;
      default:
        break;
    }
  };

  handleAddOrUpdateCardDetails = () => {
    const { isCardDetailsFounded } = this.state;
    if(isCardDetailsFounded){
      this.handleUpdateCardDetailsApi()
    }else{
      this.handleAddCardDetailsApi()
    }
  };

  handleUpdateCardDetailsApi = () => {
    const formData = new FormData();
    const { cardDetails, token } = this.state;
    
    formData.append("cardholder_name", cardDetails.cardholderName);
    formData.append("card_number", cardDetails.cardNumber);
    formData.append("expiration_date", cardDetails.expiration);
    formData.append("cvv", cardDetails.cvv);

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateCardDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCardAndAddressDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddCardDetailsApi = () => {
    const { cardDetails, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      cardholder_name: cardDetails.cardholderName,
      card_number: cardDetails.cardNumber,
      expiration_date: cardDetails.expiration,
      cvv: cardDetails.cvv,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddCardDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCardDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePaymentDoneModalClose = () => {
    this.setState({ isPaymentDone: false });
    this.getRemainingCountOfPlan(this.state.token);
  };
  // Customizable Area End
}
