import React from "react"
import { Component } from "react"

export default class CommingSoon extends Component {
  currentPath = window.location.pathname;

  handleGoBack = () => {
    window.history.back();
  };

  render() {
    return (
      <>
        <div style={styles.container}>
          <div style={styles.innerContainer}>
            <b>
              {this.currentPath === "/SearchHistory"
                ? "Search History Coming soon"
                : this.currentPath === "/Contact-Us"
                ? "ContactUs Coming soon"
                : "Profile Coming soon"}
            </b>
            <button onClick={this.handleGoBack}>Back</button>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  container: {
    padding: "45px 40px",
    borderRadius: "8px",
    height: "100%",
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ECFAFF",
    height: "100%",
  },
};