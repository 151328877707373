import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface TermsAndConditions {
  id: string;
  type: string;
  attributes: {
      description: string;
  };
}

interface TermsAndConditionsData {
  data: TermsAndConditions
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsAndConditionsData: TermsAndConditions | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TermsAndConditionsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTermsAndConditionsDataCallId : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      termsAndConditionsData: null,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiGetTermsAndConditionsDataCallId
      ) {
        this.handleSuccess(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.getTermsAndConditionsData();
    return Promise.resolve();
  }

  getTermsAndConditionsData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTermsAndConditionsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionsDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSuccess = (responseJson: TermsAndConditionsData) => {
    this.setState({ termsAndConditionsData: responseJson.data });
  }

  handleSignupNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountRegistration");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
