import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleTokenError } from "../../../components/src/Utility";

interface CPTCodeAttributes {
  service_date: string;
  cpt_code: string;
  billed_charge: number;
  zip_code: string;
  payment: number;
  units: number;
  procedure_code_description: string;
}

interface CPTCodeData {
  id: string;
  type: string;
  attributes: CPTCodeAttributes;
}

interface CPTCodeResponse {
  error?: string;
  data: CPTCodeData[];
}

interface RemainCptCodeResponse {
  errors?: [];
  free_cpt_code: number;
  cpt_code: number | null;
  current_plan: string | null;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}

interface ResponseSubscriptionPlan{
  plans: SubscriptionPlan[]
}

interface CardAndAddressDetails {
  id: number;
  cardholder_name: string;
  card_number: string;
  expiration_date: string;
  cvv: number | string;
  country1: string;
  address_line: string;
  city: string;
  state: string;
  zip: string;
  country2: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface CardDetailsResponse {
  errors?: string;
  message: string;
  data: CardAndAddressDetails;
}

interface CanclePlanResponse{
  errors?: [];
  message: string;
}

interface CardDetailsFormValues {
  cardholderName: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
}

interface StripeErrorResponse {
  code: string;
  doc_url: string;
  message: string;
  param: string; 
  request_log_url: string; 
  type: string;
}

interface ResponseDataForStripePayment {
  error?: "";
}

interface ResponseData {
  errors?: [];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isZipCodeEntered: boolean;
  zipCode: string;
  cptCode: string;
  zipCodeError: string;
  cptCodeError: string;
  token: string;
  cptCodeResponse: CPTCodeData[];
  selectedCPTCode: string;
  selectedCPTCodeId: string;
  isPlanCancelled: boolean;
  isPlanExpired: boolean;
  remainCPTCodeResponse: RemainCptCodeResponse;
  isPremiumPlan: boolean;
  plans: SubscriptionPlan[];
  freePlan: SubscriptionPlan;
  basicPlan: SubscriptionPlan;
  premiumPlan: SubscriptionPlan;
  isCardDetailsFounded: boolean;
  cardDetailsFromGet: CardAndAddressDetails;
  planById: SubscriptionPlan;
  isPremiumPlanSelected: boolean;
  isCancelModal: boolean;
  isEditModal: boolean;
  openFreeConfirmationModal: boolean;
  openBasicConfirmationModal: boolean;
  openPremiumConfirmationModal: boolean;
  switchedPlanName: string;
  isPlanSuccessfullyCancelled: boolean;
  isPlanSuccessfullyChanged: boolean;
  isCardDetailsModal: boolean;
  cardNumberError: string;
  expirationError: string;
  cvvError: string;
  cardDetails: CardDetailsFormValues;
  isFromPurchaseAgain: boolean;
  isPaymentDone: boolean;
  isEditModalForPurchaseAgain: boolean;
  isFreePlanHovered: boolean;
  isBasicPlanHovered: boolean;
  isPremiumPlanHovered: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CptCodeControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetCptCodeList: string = "";
  apiGetPlanData: string = '';
  apiSwitchToFreePlan: string = '';
  apiGetPlanDataById: string = '';
  apiStripePayment: string = '';
  apiGetCardDetails: string = '';
  apiUpdateCardDetailsCallId: string = '';
  apiAddCardDetails: string = '';
  apiGetRemainingCptCodeCount: string = '';
  apiCancleCurrentPlan: string = '';
  apiFreePlanPurchaseAgains: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isZipCodeEntered: false,
      zipCode: "",
      cptCode: "",
      zipCodeError: "",
      cptCodeError: "",
      token: "",
      cptCodeResponse: [],
      selectedCPTCode: "",
      selectedCPTCodeId: "",
      isPlanCancelled: false,
      isPlanExpired: false,
      remainCPTCodeResponse: {
        free_cpt_code: 0,
        cpt_code: null,
        current_plan: null,
      },
      cardDetails: {
        cardholderName: "",
        cardNumber: "",
        expiration: "",
        cvv: "",
      },
      isPremiumPlan: false,
      plans: [],
      freePlan: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      basicPlan: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      premiumPlan: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      isCardDetailsFounded: false,
      cardDetailsFromGet: {
        id: 0,
        cardholder_name: "",
        card_number: "",
        expiration_date: "",
        cvv: "",
        country1: "",
        address_line: "",
        city: "",
        state: "",
        zip: "",
        country2: "",
        account_id: 0,
        created_at: "",
        updated_at: "",
      },
      planById: {
        id: 0,
        name: "",
        duration: "",
        price: 0,
        details: null,
        created_at: "",
        updated_at: "",
        cpt_code: 0,
        plan1: false,
        plan2: false,
        features: "",
      },
      isPremiumPlanSelected: false,
      isCancelModal: false,
      isEditModal: false,
      openFreeConfirmationModal: false,
      openBasicConfirmationModal: false,
      openPremiumConfirmationModal: false,
      switchedPlanName: "",
      isPlanSuccessfullyCancelled: false,
      isPlanSuccessfullyChanged: false,
      isCardDetailsModal: false,
      cardNumberError: "",
      expirationError: "",
      cvvError: "",
      isFromPurchaseAgain: false,
      isPaymentDone: false,
      isEditModalForPurchaseAgain: false,
      isFreePlanHovered: false,
      isBasicPlanHovered: false,
      isPremiumPlanHovered: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      handleTokenError(responseJson);

      switch (apiRequestCallId) {
        case this.apiGetCptCodeList:
          this.handleCptCodeResponse(responseJson);
          break;

        case this.apiGetRemainingCptCodeCount:
          this.handlSuccessCptCodeCount(responseJson);
          break;

        case this.apiGetPlanData:
          this.handlePlanDataReturn(responseJson);
          break;

        case this.apiGetCardDetails:
          this.handleGetCardDetails(responseJson);
          break; 

        case this.apiGetPlanDataById:
          this.setState({ 
            planById: responseJson.plans, 
            isPremiumPlanSelected: responseJson.plans.name === "premium" 
          });
          break;

        case this.apiCancleCurrentPlan:
          this.handleCancelPlanSuccess(responseJson);
          break; 

        case this.apiSwitchToFreePlan:
          if(!responseJson.error){
            this.handleFreePlanSuccess();
          }
          break;

        case this.apiStripePayment:
          this.handleStripePaymentSuccess(responseJson)
          break;

        case this.apiUpdateCardDetailsCallId:
          this.handleUpdateCardDetails(responseJson);
          break;
  
        case this.apiAddCardDetails:
          this.handleCardAddSuccess(responseJson);
          break;

        case this.apiFreePlanPurchaseAgains:
          this.handleFreePlanPurchaseAgainSuccess();
          break;
    
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    this.getPlanData();
    return Promise.resolve()
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getRemainingCountOfPlan(token);
    this.getCardDetails(token);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.cptCode !== this.state.cptCode){
      this.GetCptCodeList()
    }
  }

  handleFreePlanPurchaseAgainSuccess = () => {
    this.setState({ isEditModalForPurchaseAgain: false, isFromPurchaseAgain: false, isPaymentDone: true })
  };

  handleUpdateCardDetails = (responseJson: ResponseData) => {
    if (!responseJson.errors) {
      this.handleCardDetailsModalClose();
      const { isFromPurchaseAgain } = this.state;
      if(isFromPurchaseAgain){
        this.setState({ isFromPurchaseAgain: false, isPaymentDone: true });
      }
      else{
        this.setState({ isPlanSuccessfullyChanged: true })
      } 
    }
  }

  handleCardAddSuccess = (responseJson : ResponseData) => {
    if (!responseJson.errors) {
      this.handleCardDetailsModalClose();
      const { isFromPurchaseAgain } = this.state;
      if(isFromPurchaseAgain){
        this.setState({ isFromPurchaseAgain: false, isPaymentDone: true });
      }
      else{
        this.setState({ isPlanSuccessfullyChanged: true })
      } 
    }
  }

  handleCancelPlanSuccess = (responseJson : CanclePlanResponse) => {
    if(!responseJson.errors){
      this.setState({ isPlanSuccessfullyCancelled: true, isCancelModal: false });
    }
  };

  handleStripePaymentSuccess = (responseJson : ResponseDataForStripePayment) => {
    if (!responseJson.error) {
      this.handleAddOrUpdateCardDetails();
    }
  };

  handleFreePlanSuccess = () => {
    this.setState({ openFreeConfirmationModal: false, isPlanSuccessfullyChanged: true });
  }

  handleGetCardDetails = (responseJson: CardDetailsResponse) => {
    if (responseJson.errors) {
      this.setState({ isCardDetailsFounded: false });
    } else {
      let formattedValue = "";
      if (responseJson.data && responseJson.data.card_number) {
        const inputValue = responseJson.data.card_number.replace(/\s+/g, "");
        formattedValue = (inputValue.match(/.{1,4}/g) as RegExpMatchArray).join(" ");
      }
      const formattedData = {
        ...responseJson.data,
        card_number: formattedValue,
      };
      this.setState({ isCardDetailsFounded: true, cardDetailsFromGet: formattedData });
    }
  }

  handlePlanDataReturn = (responseJson : ResponseSubscriptionPlan) => {
    const { plans } = responseJson;
    const defaultPlan = {
      id: 0,
      name: "",
      duration: "",
      price: 0,
      details: null,
      created_at: "",
      updated_at: "",
      cpt_code: 0,
      plan1: false,
      plan2: false,
      features: "",
    }
    const freePlan = plans.find(plan => plan.name === 'free') ?? defaultPlan;
    const basicPlan = plans.find(plan => plan.name === 'basic') ?? defaultPlan;
    const premiumPlan = plans.find(plan => plan.name === 'premium') ?? defaultPlan;

    this.setState({ 
      plans,
      freePlan, 
      basicPlan, 
      premiumPlan
    });
  }

  getPlanData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlanDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlSuccessCptCodeCount = (responseJson : RemainCptCodeResponse) => {
    if(!responseJson.errors){
      this.setState({ 
        remainCPTCodeResponse: responseJson, 
        isPremiumPlan: responseJson.current_plan === "premium",
      })
    }
  }

  handleCptCodeResponse = (responseJson : CPTCodeResponse) => {
    if(responseJson.error){
      this.setState({ 
        cptCodeResponse: [], 
        selectedCPTCode: "", 
        selectedCPTCodeId: "", 
      });
      if(responseJson.error.includes("active subscription")){
        this.setState({ isPlanCancelled: true, isPlanExpired: false });
      }
      if(responseJson.error.includes("maximum search limit")){
        this.setState({ isPlanExpired: true, isPlanCancelled:false });
      }
      if(responseJson.error.includes("No matching ZIP codes found")){
        this.setState({ cptCodeError: "We couldn't find Records. Please check your entry and try again" })
      }
    }else{
      this.setState({ cptCodeResponse: responseJson.data });
    }
  }

  GetCptCodeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCptCodeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCPTCodedataEndPoint}?zip_code=${this.state.zipCode}&cpt_code_keyword=${this.state.cptCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSubmitZipCode = () => {
    const zipCode = this.state.zipCode;

    if (zipCode.trim() === "") {
      this.setState({ zipCodeError: configJSON.zipCodeRequiredError });
    } else {
      this.setState({ zipCodeError: "", isZipCodeEntered: true });
    }
  };

  handleSubmitCptCode = () => {
    const { cptCode, selectedCPTCode, zipCode } = this.state;

    if (cptCode.trim() !== "") {
      this.setState({ cptCodeError: "" });
      const selectedData = {
        cptCode: selectedCPTCode,
        zipCode: zipCode
      };
      setStorageData('selectedCptCodeData', JSON.stringify(selectedData))
      this.handleNavigationToSubscriberCost();
    } else {
      this.setState({ cptCodeError: configJSON.cptCodeRequiredError });
    }
  };

  handleNavigationToSubscriberCost = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriberCost");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleCptCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cptCode = event.target.value;
    this.setState({ cptCodeError: "", cptCode: cptCode });
  };

  handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zipCode = event.target.value;

    if (zipCode.trim().length < 5 || zipCode.trim().length > 5) {
      this.setState({
        zipCodeError: configJSON.zipCodeError,
        zipCode: zipCode,
      });
    } else {
      this.setState({ zipCodeError: "", zipCode: zipCode });
    }
  };

  handleRadioChange = (cptCode: string, id: string) => {
    this.setState({ selectedCPTCode: cptCode, selectedCPTCodeId: id, });
  };

  getRemainingCountOfPlan = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRemainingCptCodeCount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRemainingCountOfPlan
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPlanDataById = (id : number | undefined) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/plans/${id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleOpenCancelModal = () => {
    this.setState({ isCancelModal: true, isEditModal: false });
  }

  handleOpenEditModal = () => {
    this.setState({ isPlanExpired: false, isEditModal: true });
  }

  handleEditModalClose = () => {
    this.setState({ isEditModal: false });
  }

  openFreeConfirmationModal = () => {
    this.handleEditModalClose()
    this.setState({ openFreeConfirmationModal: true, switchedPlanName: "Free" });
  };

  openBasicConfirmationModal = () => {
    this.handleEditModalClose()
    this.setState({ openBasicConfirmationModal: true, switchedPlanName: "Basic" });
  };

  openPremiumConfirmationModal = () => {
    this.handleEditModalClose()
    this.setState({ openPremiumConfirmationModal: true, switchedPlanName: "Premium" });
  };

  handlePlanExpiredModalClose = () => {
    this.setState({ isPlanExpired: false });
  };

  handlePlanCancelledModalClose = () => {
    this.setState({ isPlanCancelled: false });
  };

  handleCancelModalClose = () => {
    this.setState({ isCancelModal: false, isEditModal: true });
  }

  cancelCurrentPlan = (id : number | undefined) => {
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const httpBody = {
      plan_id: id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCancleCurrentPlan = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancleCurrentPlan
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlePlanCancelledSuccessfullyModalClose = () => {
    this.setState({ isPlanSuccessfullyCancelled: false });
    this.getRemainingCountOfPlan(this.state.token);
  };

  handlePremiumConfirmationModalClose = () => {
    this.setState({ openPremiumConfirmationModal: false,  switchedPlanName: "" });
    this.handleOpenEditModal();
  };

  handleFreeConfirmationModalClose = () => {
    this.setState({ openFreeConfirmationModal: false,  switchedPlanName: "" });
    this.handleOpenEditModal();
  };

  handleBasicConfirmationModalClose = () => {
    this.setState({ openBasicConfirmationModal: false, switchedPlanName: "" });
    this.handleOpenEditModal();
  };

  handlePremiumConfirmation = (id : number | undefined) => {
    this.handleEditModalClose();
    this.setState({ openPremiumConfirmationModal: false });
    this.getPlanDataById(id);
    this.setState({ isCardDetailsModal: true });
  };

  handleBasicConfirmation = (id : number | undefined) => {
    this.handleEditModalClose();
    this.setState({ openBasicConfirmationModal: false });
    this.getPlanDataById(id);
    this.setState({ isCardDetailsModal: true });
  };

  handleFreeConfirmation = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const body = {
      plan: "free"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSwitchToFreePlan = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.switchApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePlanChangedSuccessfullyModalClose = () => {
    this.setState({ isPlanSuccessfullyChanged: false });
    this.getRemainingCountOfPlan(this.state.token)
  };

  handleCardDetailsModalClose = () => {
    this.setState({ 
      isCardDetailsModal: false,
      isFreePlanHovered: false,
      isBasicPlanHovered: false,
      isPremiumPlanHovered: false,
     });
  };

  handleCardDetailsSubmit = (values : CardDetailsFormValues) => {
    const formattedValues = {
      ...values,
      cardNumber: values.cardNumber.replace(/\s+/g, ""),
    };
    this.createStripeToken(formattedValues);
  };

  createStripeToken = async (values: CardDetailsFormValues) => {
    const [expMonth, expYear] = values.expiration.split('/');
    const formData = new URLSearchParams();
    formData.append('card[number]', values.cardNumber);
    formData.append('card[exp_month]', expMonth);
    formData.append('card[exp_year]', expYear);
    formData.append('card[cvc]', values.cvv);
  
    await fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${configJSON.publishment}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.handleStripeTokenGenerationError(data.error);
        } else {
          this.setState({ cardDetails: values })
          this.handleStripePayment(data.id)
        }
      });
  };

  handleStripePayment = (stripeToken : string) => {
    const { planById, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      plan_id: `${planById.id}`,
      data:{
        token_card: stripeToken
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStripePayment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripePaymentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStripeTokenGenerationError = (error: StripeErrorResponse) => {
    switch (error.code) {
      case 'incorrect_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_expiry_month':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_expiry_year':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_cvc':
        this.setState({ cvvError : error.message });
        break;
      default:
        break;
    }
  };

  getCardDetails = (token : string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCardDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressAndCardDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddOrUpdateCardDetails = () => {
    const { isCardDetailsFounded } = this.state;
    if(isCardDetailsFounded){
      this.handleUpdateCardDetailsApi()
    }else{
      this.handleAddCardDetailsApi()
    }
  };

  handleUpdateCardDetailsApi = () => {
    const formData = new FormData();
    const { cardDetails, token } = this.state;
    
    formData.append("cardholder_name", cardDetails.cardholderName);
    formData.append("card_number", cardDetails.cardNumber);
    formData.append("expiration_date", cardDetails.expiration);
    formData.append("cvv", cardDetails.cvv);

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateCardDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCardAndAddressDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddCardDetailsApi = () => {
    const { cardDetails, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      cardholder_name: cardDetails.cardholderName,
      card_number: cardDetails.cardNumber,
      expiration_date: cardDetails.expiration,
      cvv: cardDetails.cvv,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddCardDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCardDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onCardNumberChange = () => {
    this.setState({ cardNumberError: '' });
  };

  onExpirationChange = () => {
    this.setState({ expirationError: '' });
  };

  onCvvChange = () => {
    this.setState({ cvvError: '' });
  };

  handleOpenEditModalForPurchaseAgain = () => {
    this.setState({ isEditModalForPurchaseAgain: true, isPlanCancelled: false });
  };

  handleCloseEditModalForPurchaseAgain = () => {
    this.setState({ isEditModalForPurchaseAgain: false });
  };

  handleFreePlanHovered = () => {
    this.setState({ isFreePlanHovered: true });
  };

  handleFreePlanLeave = () => {
    this.setState({ isFreePlanHovered: false });
  };

  handleBasicPlanHovered = () => {
    this.setState({ isBasicPlanHovered: true });
  };

  handleBasicPlanLeave = () => {
    this.setState({ isBasicPlanHovered: false });
  };

  handlePremiumPlanHovered = () => {
    this.setState({ isPremiumPlanHovered: true });
  };

  handlePremiumPlanLeave = () => {
    this.setState({ isPremiumPlanHovered: false });
  };

  handleSelectPlanButton = (id: number) => {
    this.getPlanDataById(id);
    this.setState({ isCardDetailsModal: true, isEditModalForPurchaseAgain: false, isFromPurchaseAgain: true });
  };

  freePlanPurchaseAgain = (id : number) => {
    this.setState({ isFromPurchaseAgain: true });
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const httpBody = {
      plan_id: id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFreePlanPurchaseAgains = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.freePlanPurchaseAgain
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePaymentDoneModalClose = () => {
    this.setState({ isPaymentDone: false });
    this.getRemainingCountOfPlan(this.state.token);
  };
  // Customizable Area End
}
