import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Radio,
  Divider,
  Modal,
  Grid,
  FormControl,
  FormLabel,
  InputAdornment
} from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { imgArrowForward, imgCancel, imgCheck, imgCloseModal, imgInfo, imgCheckmark, imgCard, imgOk } from "./assets";
import { withStyles } from "@mui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { getErrorAndHelperText, validateExpirationYear, validateExpirationMonth } from "../../../components/src/Utility";
import * as Yup from 'yup';

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}
// Customizable Area End

import CptCodeControllerWeb, {
  Props,
  configJSON,
} from "./CptCodeController.web";

export default class CptCode extends CptCodeControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };

  capitalizeFirstLetter = (text : string | null) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  renderIf = <P,>(condition: boolean, component: React.ReactElement<P>) => {
    return condition ? component : null;
  };

  renderConditionalComponent = <P, Q>(
    condition: boolean,
    trueComponent: React.ReactElement<P>,
    falseComponent: React.ReactElement<Q>
  ) => {
    return condition ? trueComponent : falseComponent;
  };

  getColor = (isPremiumPlanSelected : boolean) => {
    return isPremiumPlanSelected ? "#F69544" : "#94A3B8";
  };

  getImageSrc = (isPremiumPlanSelected : boolean) => {
    return isPremiumPlanSelected ? imgCheck : imgCancel;
  };

  renderPlanBox(planById: SubscriptionPlan, isPremiumPlanSelected: boolean) {
    return (
      <Box sx={styles.planBox}>
        <Typography
          sx={{
            ...styles.cardModalPlanText,
            color: this.getColor(isPremiumPlanSelected),
          }}
        >
          {this.capitalizeFirstLetter(planById.name)}
        </Typography>
        <Typography sx={styles.cardModalPlanPriceText}>
          ${planById.price}
        </Typography>
        {this.renderConditionalComponent(
          isPremiumPlanSelected,
          <Typography sx={styles.cardModalPlanPriceDescription}>
            Up to{" "}
            <span style={styles.cardModalCptCodeText}>
              Unlimited CPT Code
            </span>
          </Typography>,
          <Typography sx={styles.cardModalPlanPriceDescription}>
            Basic feature for up to{" "}
            <span style={styles.cardModalCptCodeText}>
              {planById.cpt_code} CPT Code
            </span>
          </Typography>
        )}
        <Box display={"flex"} flexDirection={"column"} marginTop={"50px"}>
          <Box display={"flex"} alignItems={"center"} marginBottom={"8px"}>
            <img src={this.getImageSrc(isPremiumPlanSelected)} />
            <Typography sx={styles.cardModalMainTextDescription}>
              Access to Year Over Year Comparison.
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <img src={this.getImageSrc(isPremiumPlanSelected)} />
            <Typography sx={styles.cardModalMainTextDescription}>
              Access to Similar CPT Codes.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  validationSchemaForCardDetails = Yup.object({
    cardholderName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Cardholder Name should only contain letters")
    .required('Cardholder Name is required'),
    expiration: Yup.string()
    .test('valid-expiration', 'Invalid format. Use MM/YYYY.', validateExpirationYear)
    .test('month-range', 'Month must be between 01 and 12.', validateExpirationMonth)
    .required('Expiration is required'),
    cardNumber: Yup.string()
    .matches(/^[\d\s]+$/, "Card Number must contain only numbers")
    .required("Card Number is required"),
    cvv: Yup.string()
    .matches(/^\d+$/, 'CVV must contain only numbers')
    .required('CVV is required'),
  });

  getInitialValuesForCardDetails() {
    const{ cardDetailsFromGet } = this.state;
    return {
      cardholderName: cardDetailsFromGet.cardholder_name,
      cardNumber: cardDetailsFromGet.card_number,
      expiration: cardDetailsFromGet.expiration_date,
      cvv: `${cardDetailsFromGet.cvv}`,
    };
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isZipCodeEntered,
      cptCode,
      zipCode,
      zipCodeError,
      cptCodeError,
      cptCodeResponse,
      selectedCPTCodeId,
      remainCPTCodeResponse,
      freePlan,
      basicPlan,
      premiumPlan,
      isEditModal,
      plans,
      isCancelModal,
      isPlanCancelled,
      isPlanExpired,
      openFreeConfirmationModal,
      openBasicConfirmationModal,
      openPremiumConfirmationModal,
      isPlanSuccessfullyCancelled,
      isPlanSuccessfullyChanged,
      switchedPlanName,
      isCardDetailsModal,
      planById,
      isPremiumPlanSelected,
      cardNumberError,
      expirationError,
      cvvError,
      isEditModalForPurchaseAgain,
      isFreePlanHovered,
      isBasicPlanHovered,
      isPremiumPlanHovered,
      isPaymentDone,
    } = this.state;
    const modalStyle = modalStyles();
    const modal1Style = modalStylesConditioanal(remainCPTCodeResponse.current_plan === "free");
    const modal2Style = modalStylesConditioanal(remainCPTCodeResponse.current_plan === "basic");
    const modal3Style = modalStylesConditioanal(remainCPTCodeResponse.current_plan === "premium");
    const modalFreeStyle = modalStylesConditioanal(isFreePlanHovered);
    const modalBasicStyle = modalStylesConditioanal(isBasicPlanHovered);
    const modalPremiumStyle = modalStylesConditioanal(isPremiumPlanHovered);
    const initialValuesForCardDetails = this.getInitialValuesForCardDetails();
    const currentPlan = plans.find(plan => plan.name === remainCPTCodeResponse.current_plan);
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            <Box sx={styles.box}>
              <Box sx={styles.boxMargin}>
                <Typography sx={styles.codeText} data-test-id="headerText">
                  {isZipCodeEntered
                    ? configJSON.cptcodeText
                    : configJSON.zipcodeText}
                  {isZipCodeEntered && (
                    <LightTooltip
                      title={configJSON.tooltipText}
                      arrow
                      placement="right-end"
                      data-test-id="tooltip"
                    >
                      <IconButton>
                        <img src={imgInfo} alt="Info Icon" />
                      </IconButton>
                    </LightTooltip>
                  )}
                </Typography>
              </Box>
              <Box sx={styles.boxWidth}>
                <Box sx={styles.boxMargin}>
                  {isZipCodeEntered ? (
                    <Box sx={styles.centerBox}>
                      <TextField
                        name="cptCode"
                        value={cptCode}
                        placeholder={configJSON.cptCodePlaceholder}
                        onChange={this.handleCptCodeChange}
                        error={!!cptCodeError}
                        sx={styles.textField}
                        fullWidth
                      />
                      {cptCodeError && (
                        <Box
                          sx={styles.errorMessageWidth}
                          data-test-id="cptCodeError"
                        >
                          <Box style={styles.error}>{cptCodeError}</Box>
                        </Box>
                      )}
                      {cptCodeResponse.length > 0 && (
                        <Box sx={styles.cptCodeListBox}>
                          {cptCodeResponse.map((cptCode) => (
                            <>
                              <Box sx={styles.cptCodeItem} key={cptCode.id}>
                                <Radio
                                  sx={styles.radioButton}
                                  data-test-id="cptCodeRadioButton"
                                  checked={selectedCPTCodeId === cptCode.id}
                                  onChange={() =>
                                    this.handleRadioChange(
                                      cptCode.attributes.cpt_code,
                                      cptCode.id
                                    )
                                  }
                                />
                                <Box sx={styles.textContainer}>
                                  <Typography sx={styles.codeBoxText}>
                                    CPT CODE- {cptCode.attributes.cpt_code}
                                  </Typography>
                                  <Typography sx={styles.descriptionText}>
                                    {
                                      cptCode.attributes
                                        .procedure_code_description
                                    }
                                  </Typography>
                                </Box>
                                {selectedCPTCodeId === cptCode.id && (
                                  <Button
                                    data-test-id="code-button"
                                    variant="contained"
                                    sx={styles.seeCostButton}
                                    endIcon={<img src={imgArrowForward} />}
                                    onClick={this.handleSubmitCptCode}
                                  >
                                    See Cost
                                  </Button>
                                )}
                              </Box>
                              <Box padding={"0px 16px"}>
                                <Divider />
                              </Box>
                            </>
                          ))}
                        </Box>
                      )}
                      <Button
                        disabled={!!cptCodeError || !selectedCPTCodeId}
                        type="submit"
                        variant="contained"
                        data-test-id="cptCodeSubmit"
                        sx={
                          cptCodeResponse.length > 0
                            ? styles.continueButtonBlueWithoutMarginTop
                            : styles.continueButtonBlue
                        }
                        fullWidth
                        onClick={this.handleSubmitCptCode}
                      >
                        {configJSON.continueBtnText}
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={styles.centerBox}>
                      <TextField
                        name="zipCode"
                        value={zipCode}
                        placeholder={configJSON.zipCodePlaceholder}
                        onChange={this.handleZipCodeChange}
                        error={!!zipCodeError}
                        sx={styles.textField}
                        fullWidth
                      />
                      {zipCodeError && (
                        <Box
                          sx={styles.errorMessageWidth}
                          data-test-id="zipCodeError"
                        >
                          <Box style={styles.error}>{zipCodeError}</Box>
                        </Box>
                      )}
                      <Button
                        disabled={!!zipCodeError}
                        type="submit"
                        variant="contained"
                        data-test-id="zipCodeSubmit"
                        sx={styles.continueButtonBlue}
                        fullWidth
                        onClick={this.handleSubmitZipCode}
                      >
                        {configJSON.continueBtnText}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Modal
          open={isEditModal}
          data-test-id="planModalClose"
          onClose={this.handleEditModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Box sx={modalStyle.modalPlanInner}>
            <Box sx={modalStyle.planModalInner}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box sx={modal1Style.boxBorder}>
                  <Box padding={"24px 24px 0px"}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      marginBottom={"16px"}
                    >
                      <Typography sx={modalStyle.freePlanText}>
                        {this.capitalizeFirstLetter(freePlan.name)}
                      </Typography>
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan === "free",
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography sx={modalStyle.currentPlanText}>
                            Current Plan
                          </Typography>
                          {this.renderIf(
                            remainCPTCodeResponse.free_cpt_code === 0,
                            <Typography sx={modalStyle.expiredPlanText}>
                              Expired!
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modal1Style.planPriceModal}>
                        ${freePlan.price}
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modal1Style.cptCodeText}>
                        {freePlan.cpt_code} CPT Code
                      </span>
                    </Typography>
                    <Typography sx={modalStyle.greenFreeText}>
                      (You can use up to {freePlan.cpt_code} CPT codes for the
                      rest of your life*.)
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      marginBottom={"23px"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginBottom={"8px"}
                      >
                        <img src={imgCancel} />
                        <Typography sx={modal1Style.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCancel} />
                        <Typography sx={modal1Style.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      {this.renderConditionalComponent(
                        remainCPTCodeResponse.current_plan === "free",
                        <Button
                          variant="outlined"
                          sx={modal1Style.cancelPlanButton}
                          fullWidth
                          data-test-id="freePlanCancel"
                          onClick={() => this.handleOpenCancelModal()}
                        >
                          Cancel Plan
                        </Button>,
                        <Button
                          variant="outlined"
                          sx={modal1Style.continueModalButton}
                          fullWidth
                          data-test-id="freePlanContinue"
                          onClick={() => this.openFreeConfirmationModal()}
                          disabled={remainCPTCodeResponse.free_cpt_code === 0}
                        >
                          Select Plan
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={modal2Style.boxBorder}>
                  <Box padding={"28px"}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      marginBottom={"16px"}
                    >
                      <Typography sx={modalStyle.freePlanText}>
                        {this.capitalizeFirstLetter(basicPlan.name)}
                      </Typography>
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan === "basic",
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography sx={modalStyle.currentPlanText}>
                            Current Plan
                          </Typography>
                          {this.renderIf(
                            remainCPTCodeResponse.cpt_code === 0,
                            <Typography sx={modalStyle.expiredPlanText}>
                              Expired!
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modal2Style.planPriceModal}>
                        ${basicPlan.price}
                      </Typography>
                      <Typography sx={modal2Style.planPriceUnitModal}>
                        /month
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modal2Style.cptCodeText}>
                        {basicPlan.cpt_code} CPT Code / month.
                      </span>
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      marginBottom={"23px"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginBottom={"8px"}
                      >
                        <img src={imgCancel} />
                        <Typography sx={modal2Style.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCancel} />
                        <Typography sx={modal2Style.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan === "basic" &&
                          remainCPTCodeResponse.cpt_code !== 0,
                        <Button
                          variant="outlined"
                          sx={modal1Style.cancelPlanButton}
                          fullWidth
                          data-test-id="basicPlanCancel"
                          onClick={() => this.handleOpenCancelModal()}
                        >
                          Cancel Plan
                        </Button>
                      )}
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan === "basic" &&
                          remainCPTCodeResponse.cpt_code === 0,
                        <Button
                          variant="outlined"
                          sx={modal2Style.upgradeModalButton}
                          fullWidth
                          data-test-id="basicUpgradePlanContinue"
                          onClick={() => this.openBasicConfirmationModal()}
                        >
                          Upgrade Plan
                        </Button>
                      )}
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan !== "basic",
                        <Button
                          variant="outlined"
                          sx={modal2Style.continueModalButton}
                          fullWidth
                          data-test-id="basicPlanContinue"
                          onClick={() => this.openBasicConfirmationModal()}
                        >
                          Select Plan
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={modal3Style.boxBorder}>
                  <Box padding={"28px"}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      marginBottom={"16px"}
                    >
                      <Typography sx={modalStyle.premiumPlanText}>
                        {this.capitalizeFirstLetter(premiumPlan.name)}
                      </Typography>
                      {this.renderIf(
                        remainCPTCodeResponse.current_plan === "premium",
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography sx={modalStyle.currentPlanText}>
                            Current Plan
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modal3Style.planPriceModal}>
                        ${premiumPlan.price}
                      </Typography>
                      <Typography sx={modal3Style.planPriceUnitModal}>
                        /month
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modal3Style.cptCodeText}>
                        Unlimited CPT Code
                      </span>
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      marginBottom={"23px"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginBottom={"8px"}
                      >
                        <img src={imgCheck} />
                        <Typography sx={modal3Style.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCheck} />
                        <Typography sx={modal3Style.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      {this.renderConditionalComponent(
                        remainCPTCodeResponse.current_plan === "premium",
                        <Button
                          variant="outlined"
                          sx={modal1Style.cancelPlanButton}
                          fullWidth
                          data-test-id="premiumPlanCancel"
                          onClick={() => this.handleOpenCancelModal()}
                        >
                          Cancel Plan
                        </Button>,
                        <Button
                          variant="outlined"
                          sx={modal3Style.continueModalButton}
                          fullWidth
                          data-test-id="premiumPlanContinue"
                          onClick={() => this.openPremiumConfirmationModal()}
                        >
                          Select Plan
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openFreeConfirmationModal}
          onClose={this.handleFreeConfirmationModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="freeConfirmation-modal-close"
                onClick={() => this.handleFreeConfirmationModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography
                sx={{ ...styles.modalDeleteContext, marginBottom: 0 }}
              >
                Are You Sure You Want To Change Your Plan From
              </Typography>
              <Typography sx={styles.modalDeleteContext}>
                <b>
                  {this.capitalizeFirstLetter(
                    remainCPTCodeResponse.current_plan
                  )}
                </b>{" "}
                to <b>Free Plan</b>
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.cancelDeleteButtonFullwidth}
                  fullWidth
                  data-test-id="freeConfirmation-modal-close-cancel"
                  onClick={() => this.handleFreeConfirmationModalClose()}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="freeConfirmation-modal-submit"
                  sx={styles.submitDeleteButtonFullwodth}
                  onClick={() => this.handleFreeConfirmation()}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openBasicConfirmationModal}
          onClose={this.handleBasicConfirmationModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="basicConfirmation-modal-close"
                onClick={() => this.handleBasicConfirmationModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography
                sx={{ ...styles.modalDeleteContext, marginBottom: 0 }}
              >
                Are You Sure You Want To Change Your Plan From
              </Typography>
              <Typography sx={styles.modalDeleteContext}>
                <b>
                  {this.capitalizeFirstLetter(
                    remainCPTCodeResponse.current_plan
                  )}
                </b>{" "}
                to <b>Basic Plan</b>
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.cancelDeleteButtonFullwidth}
                  fullWidth
                  data-test-id="basicConfirmation-modal-close-cancel"
                  onClick={() => this.handleBasicConfirmationModalClose()}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="basicConfirmation-modal-submit"
                  sx={styles.submitDeleteButtonFullwodth}
                  onClick={() => this.handleBasicConfirmation(basicPlan.id)}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openPremiumConfirmationModal}
          onClose={this.handlePremiumConfirmationModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="premiumConfirmation-modal-close"
                onClick={() => this.handlePremiumConfirmationModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography
                sx={{ ...styles.modalDeleteContext, marginBottom: 0 }}
              >
                Are You Sure You Want To Change Your Plan From
              </Typography>
              <Typography sx={styles.modalDeleteContext}>
                <b>
                  {this.capitalizeFirstLetter(
                    remainCPTCodeResponse.current_plan
                  )}
                </b>{" "}
                to <b>Premium Plan</b>
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.cancelDeleteButtonFullwidth}
                  fullWidth
                  data-test-id="premiumConfirmation-modal-close-cancel"
                  onClick={() => this.handlePremiumConfirmationModalClose()}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="premiumConfirmation-modal-submit"
                  sx={styles.submitDeleteButtonFullwodth}
                  onClick={() => this.handlePremiumConfirmation(premiumPlan.id)}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isCancelModal}
          onClose={this.handleCancelModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="cancel-modal-close"
                onClick={() => this.handleCancelModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography sx={styles.modalDeleteContext}>
                Are you sure you want to <b>cancel</b> your current Plan.
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.backButtonFullwidth}
                  fullWidth
                  data-test-id="cancel-modal-close-cancel"
                  onClick={() => this.handleCancelModalClose()}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="cancel-modal-submit"
                  sx={styles.cancelButtonFullwodth}
                  onClick={() => this.cancelCurrentPlan(currentPlan?.id)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isPlanSuccessfullyCancelled}
          onClose={this.handlePlanCancelledSuccessfullyModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="plan-cancelled-successfully-modal-close"
                onClick={() => this.handlePlanCancelledSuccessfullyModalClose()}
              />
            </Box>
            <Box sx={styles.modalDeleteSuccessfully}>
              <img src={imgCheckmark} />
              <Typography
                sx={{
                  ...styles.modalDeleteSuccessfullyContext,
                  textAlign: "center",
                }}
              >
                Your subscription has been successfully cancelled.
              </Typography>
              <Button
                variant="contained"
                type="submit"
                sx={styles.okayButton}
                data-test-id="okayButtonCancelled"
                onClick={() => this.handlePlanCancelledSuccessfullyModalClose()}
              >
                {configJSON.okay}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isPlanExpired}
          onClose={this.handlePlanExpiredModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="PlanExpired-modal-close"
                onClick={() => this.handlePlanExpiredModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography
                sx={{ ...styles.modalDeleteContext, marginBottom: 0 }}
              >
                You've reached the limit for the {currentPlan?.name} plan.
              </Typography>
              <Typography sx={styles.modalDeleteContext}>
                Upgrade to the <b>Premium Plan</b> for more access."
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.cancelDeleteButtonFullwidth}
                  fullWidth
                  data-test-id="PlanExpired-modal-close-cancel"
                  onClick={() => this.handlePlanExpiredModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="PlanExpired-modal-submit"
                  sx={styles.submitDeleteButtonFullwodth}
                  onClick={() => this.handleOpenEditModal()}
                >
                  Upgrade
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isPlanCancelled}
          onClose={this.handlePlanCancelledModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="PlanCancelled-modal-close"
                onClick={() => this.handlePlanCancelledModalClose()}
              />
            </Box>
            <Box sx={styles.modalDelete}>
              <Typography
                sx={{ ...styles.modalDeleteContext, marginBottom: 0 }}
              >
                You don't have any active plan
              </Typography>
              <Typography sx={styles.modalDeleteContext}>
                Please select the plan.
              </Typography>
              <Box display={"flex"} width={"60%"}>
                <Button
                  variant="contained"
                  sx={styles.cancelDeleteButtonFullwidth}
                  fullWidth
                  data-test-id="PlanCancelled-modal-close-cancel"
                  onClick={() => this.handlePlanCancelledModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  data-test-id="PlanCancelled-modal-submit"
                  sx={styles.submitDeleteButtonFullwodth}
                  onClick={() => this.handleOpenEditModalForPurchaseAgain()}
                >
                  Upgrade
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isEditModalForPurchaseAgain}
          data-test-id="editModalPurchaseAgainClose"
          onClose={this.handleCloseEditModalForPurchaseAgain}
          aria-labelledby="modal-modal-title"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle.modalPlanInner}>
            <Box sx={modalStyle.planModalInner}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={modalFreeStyle.boxBorder}
                  onMouseEnter={this.handleFreePlanHovered}
                  onMouseLeave={this.handleFreePlanLeave}
                  data-test-id="planFreeHovered"
                >
                  <Box padding={"24px 24px 0px"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={"16px"}
                      justifyContent={"space-between"}
                    >
                      <Typography sx={modalStyle.freePlanText}>
                        {this.capitalizeFirstLetter(freePlan.name)}
                      </Typography>
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modalFreeStyle.planPriceModal}>
                        ${freePlan.price}
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modalFreeStyle.cptCodeText}>
                        {freePlan.cpt_code} CPT Code
                      </span>
                    </Typography>
                    <Typography sx={modalStyle.greenFreeText}>
                      (You can use up to {freePlan.cpt_code} CPT codes for the
                      rest of your life*.)
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      marginBottom={"23px"}
                      flexDirection={"column"}
                    >
                      <Box
                        display={"flex"}
                        marginBottom={"8px"}
                        alignItems={"center"}
                      >
                        <img src={imgCancel} />
                        <Typography sx={modalFreeStyle.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCancel} />
                        <Typography sx={modalFreeStyle.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      <Button
                        variant="outlined"
                        disabled={remainCPTCodeResponse.free_cpt_code === 0}
                        sx={modalFreeStyle.selectPlanButton}
                        fullWidth
                        data-test-id="freePlanPurchaseAgainContinue"
                        onClick={() => this.freePlanPurchaseAgain(freePlan.id)}
                      >
                        Select Plan
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={modalBasicStyle.boxBorder}
                  onMouseEnter={this.handleBasicPlanHovered}
                  onMouseLeave={this.handleBasicPlanLeave}
                  data-test-id="planBasicHovered"
                >
                  <Box padding={"28px"}>
                    <Box
                      marginBottom={"16px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography sx={modalStyle.freePlanText}>
                        {this.capitalizeFirstLetter(basicPlan.name)}
                      </Typography>
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modalBasicStyle.planPriceModal}>
                        ${basicPlan.price}
                      </Typography>
                      <Typography sx={modalBasicStyle.planPriceUnitModal}>
                        /month
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modalBasicStyle.cptCodeText}>
                        {basicPlan.cpt_code} CPT Code / month.
                      </span>
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      marginBottom={"23px"}
                      flexDirection={"column"}
                    >
                      <Box
                        display={"flex"}
                        marginBottom={"8px"}
                        alignItems={"center"}
                      >
                        <img src={imgCancel} />
                        <Typography sx={modalBasicStyle.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCancel} />
                        <Typography sx={modalBasicStyle.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={modalBasicStyle.selectPlanButton}
                        onClick={() =>
                          this.handleSelectPlanButton(basicPlan.id)
                        }
                        data-test-id="basicPlanPurchaseAgainContinue"
                      >
                        Select Plan
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={modalPremiumStyle.boxBorder}
                  onMouseEnter={this.handlePremiumPlanHovered}
                  onMouseLeave={this.handlePremiumPlanLeave}
                  data-test-id="planPremiumHovered"
                >
                  <Box padding={"28px"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={"16px"}
                      justifyContent={"space-between"}
                    >
                      <Typography sx={modalStyle.premiumPlanText}>
                        {this.capitalizeFirstLetter(premiumPlan.name)}
                      </Typography>
                    </Box>
                    <Box display={"flex"} marginBottom={"10px"}>
                      <Typography sx={modalPremiumStyle.planPriceModal}>
                        ${premiumPlan.price}
                      </Typography>
                      <Typography sx={modalPremiumStyle.planPriceUnitModal}>
                        /month
                      </Typography>
                    </Box>
                    <Typography sx={modalStyle.planPriceDescription}>
                      Up to{" "}
                      <span style={modalPremiumStyle.cptCodeText}>
                        Unlimited CPT Code
                      </span>
                    </Typography>
                  </Box>
                  <Divider style={modalStyle.dividerColor} />
                  <Box padding={"24px"}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      marginBottom={"23px"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginBottom={"8px"}
                      >
                        <img src={imgCheck} />
                        <Typography sx={modalPremiumStyle.mainTextDescription}>
                          Access toYear Over Year Comparison.
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={imgCheck} />
                        <Typography sx={modalPremiumStyle.mainTextDescription}>
                          Access to Similar CPT Codes.
                        </Typography>
                      </Box>
                    </Box>
                    <Box padding={"0px 20px"}>
                      <Button
                        variant="outlined"
                        sx={modalPremiumStyle.selectPlanButton}
                        fullWidth
                        onClick={() =>
                          this.handleSelectPlanButton(premiumPlan.id)
                        }
                        data-test-id="premiumPlanPurchaseAgainContinue"
                      >
                        Select Plan
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isPaymentDone}
          data-test-id="paymentDoneModalClose"
          onClose={() => this.handlePaymentDoneModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Box sx={styles.skipModalPlanInner}>
            <Box sx={styles.skipModalInner}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Typography sx={styles.skipModalHeaderText}>
                  Congratulation! Your Payment has been Completed.
                </Typography>
                <Box marginTop={"50px"}>
                  <img src={imgOk} />
                </Box>
                <Typography sx={styles.skipDescription}>
                  Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu .
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  data-test-id="paymentDoneModal"
                  sx={styles.skipModalButton}
                  onClick={() => this.handlePaymentDoneModalClose()}
                >
                  Okay
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isPlanSuccessfullyChanged}
          onClose={this.handlePlanChangedSuccessfullyModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDeleteInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="plan-changed-successfully-modal-close"
                onClick={() => this.handlePlanChangedSuccessfullyModalClose()}
              />
            </Box>
            <Box sx={styles.modalDeleteSuccessfully}>
              <img src={imgCheckmark} />
              <Typography
                sx={{
                  ...styles.modalDeleteSuccessfullyContext,
                  textAlign: "center",
                }}
              >
                Your subscription has been successfully changed to{" "}
                {switchedPlanName} Plan
              </Typography>
              <Button
                variant="contained"
                type="submit"
                sx={styles.okayButton}
                data-test-id="okayButtonFinal"
                onClick={() => this.handlePlanChangedSuccessfullyModalClose()}
              >
                {configJSON.okay}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isCardDetailsModal}
          data-test-id="cardDetailsModalClose"
          onClose={this.handleCardDetailsModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Box sx={styles.modalCardDetailsInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgCloseModal}
                style={styles.closeDeleteIcon}
                data-test-id="cardDetailsModalCloseButton"
                onClick={() => this.handleCardDetailsModalClose()}
              />
            </Box>
            <Box sx={styles.cardDetailsModalInner}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {this.renderPlanBox(planById, isPremiumPlanSelected)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Formik
                    key={`${initialValuesForCardDetails.cardNumber}-${initialValuesForCardDetails.cardholderName}-${initialValuesForCardDetails.cvv}-${initialValuesForCardDetails.expiration}`}
                    initialValues={initialValuesForCardDetails}
                    validationSchema={this.validationSchemaForCardDetails}
                    onSubmit={(values) => this.handleCardDetailsSubmit(values)}
                  >
                    {({ errors, touched, isValid, values, handleChange }) => (
                      <Form>
                        <Box padding="0px 40px">
                          <Grid container sx={styles.gridInnerPadding}>
                            <Grid item xs={12} sx={styles.gridInnerPadding}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginBottom={"30px"}
                              >
                                <Typography
                                  sx={styles.billingHeader}
                                  textAlign="center"
                                >
                                  Add Card Details
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sx={styles.gridInnerPadding}>
                              <FormControl sx={styles.cardDetailsFormControl}>
                                <FormLabel
                                  sx={styles.cardDetailsModalLabelText}
                                >
                                  Cardholder Name
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="cardholderName"
                                  variant="outlined"
                                  type="text"
                                  placeholder="Enter Name"
                                  fullWidth
                                  sx={styles.cardModalFieldStyle}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <img src={imgCard} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    getErrorAndHelperText(
                                      "cardholderName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "cardholderName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={styles.gridInnerPadding}>
                              <FormControl sx={styles.cardDetailsFormControl}>
                                <FormLabel
                                  sx={styles.cardDetailsModalLabelText}
                                >
                                  Card Number
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="cardNumber"
                                  variant="outlined"
                                  type="text"
                                  data-test-id="cardNumberTextField"
                                  placeholder="Enter Card Number"
                                  fullWidth
                                  value={values.cardNumber}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    this.onCardNumberChange();
                                    const inputValue =
                                      event.target.value.replace(/\s+/g, "");
                                    const formattedValue =
                                      inputValue.match(/.{1,4}/g)?.join(" ") ||
                                      inputValue;
                                    handleChange("cardNumber")({
                                      ...event,
                                      target: {
                                        ...event.target,
                                        value: formattedValue,
                                      },
                                    });
                                  }}
                                  helperText={
                                    getErrorAndHelperText(
                                      "cardNumber",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      cardNumberError
                                    ).helperText
                                  }
                                  sx={styles.cardModalFieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "cardNumber",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      cardNumberError
                                    ).isError
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={styles.gridInnerPaddingRight}>
                              <FormControl sx={styles.cardDetailsFormControl}>
                                <FormLabel
                                  sx={styles.cardDetailsModalLabelText}
                                >
                                  Expiration
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="expiration"
                                  variant="outlined"
                                  type="text"
                                  placeholder="MM/YYYY"
                                  fullWidth
                                  data-test-id="expirationTextField"
                                  value={values.expiration}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange("expiration")(event);
                                    this.onExpirationChange();
                                  }}
                                  sx={styles.cardModalFieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "expiration",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      expirationError
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "expiration",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      expirationError
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={styles.gridInnerPaddingLeft}>
                              <FormControl sx={styles.cardDetailsFormControl}>
                                <FormLabel
                                  sx={styles.cardDetailsModalLabelText}
                                >
                                  CVV
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  variant="outlined"
                                  name="cvv"
                                  placeholder="Enter CVV"
                                  type="password"
                                  fullWidth
                                  data-test-id="cvvTextField"
                                  value={values.cvv}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange("cvv")(event);
                                    this.onCvvChange();
                                  }}
                                  helperText={
                                    getErrorAndHelperText(
                                      "cvv",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      cvvError
                                    ).helperText
                                  }
                                  error={
                                    getErrorAndHelperText(
                                      "cvv",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      cvvError
                                    ).isError
                                  }
                                  sx={{
                                    ...styles.cardModalFieldStyle,
                                    "& input[type=number]": {
                                      "-moz-appearance": "textfield",
                                      "&::-webkit-outer-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                      "&::-webkit-inner-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                    },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={styles.gridInnerPadding}>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={styles.continueWithPaymentButton}
                                fullWidth
                                disabled={!isValid}
                              >
                                Continue with Payment
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStylesConditioanal = (isSelectedPlan: boolean) => ({
  boxBorder: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    borderRadius: "8px",
    border: "1px solid #94A3B8",
    backgroundColor: isSelectedPlan ? "#1E293B" : "white",
  },
  planPriceModal: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    color: isSelectedPlan ? "white" : "#334155",
  },
  planPriceUnitModal: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: isSelectedPlan ? "white" : "#334155",
    marginTop: "18px",
  },
  cptCodeText:{
    color: isSelectedPlan ? "white" : "black",
  },
  mainTextDescription:{
    fontSize: "14px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: isSelectedPlan ? "white" : "#9795B5",
    textAlign: "center"
  },
  continueModalButton: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#1799CB',
    borderRadius: '8px',
    border: "1px solid #1799CB",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
      border: "1px solid #1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  selectPlanButton: {
    cursor: 'pointer',
    backgroundColor: isSelectedPlan ? "#1799CB" : '#fff',
    color: isSelectedPlan ? "#fff" : "#1799CB",
    borderRadius: '8px',
    border: "1px solid #1799CB",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: isSelectedPlan ? "#1799CB" : '#fff',
      border: "1px solid #1799CB",
      color: isSelectedPlan ? "#fff" : "#1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  upgradeModalButton: {
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    border: "1px solid #1799CB",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
      border: "1px solid #1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  cancelPlanButton: {
    cursor: 'pointer',
    backgroundColor: "#1E293B",
    color: "red",
    borderRadius: '8px',
    border: "1px solid red",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#1E293B",
      border: "1px solid red",
    },
  },
});
const modalStyles = () => ({
  currentPlanText:{
    fontWeight: 500,
    fontSize: "16px",
    color: "#34D399",
  },
  expiredPlanText:{
    fontWeight: 500,
    fontSize: "16px",
    color:"#EF4444",
  },
  greenFreeText:{
    fontSize : "12px",
    fontWeight: "400",
    color: "#059669",
    fontStyle: "italic",
  },
  dividerColor:{
    backgroundColor: '#D4D2E3',
  },
  planPriceDescription:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#94A3B8",
  },
  freePlanText:{
    fontSize: "30px",
    fontWeight: 500,
    color: "#94A3B8",
  },
  premiumPlanText:{
    fontSize: "30px",
    fontWeight: 500,
    color: "#F69544",
  },
  planModalInner:{
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "60px 60px 60px"
  },
  modalPlanInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1350px",
    transform: "Translate(-50%, -50%)",
  },
})

const styles = {
  skipModalButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    width: "35%",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
  },
  skipDescription:{
    fontSize: "24px",
    fontWeight: 400,
    color: "#64748B",
    textAlign: "center",
    marginTop: "50px",
  },
  skipModalHeaderText:{
    fontSize: "30px",
    fontWeight: 700,
    color: "black",
    textAlign: "center",
  },
  skipModalInner:{
    borderRadius: "6px",
    backgroundColor: "#F9F9FF",
    padding:"80px 175px",
  },
  skipModalPlanInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1100px",
    transform: "Translate(-50%, -50%)",
  },
  billingHeader:{
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  cardModalFieldStyle:{
    backgroundColor: "#fff",
    '& .MuiFormHelperText-root': {
      margin: 0,
      backgroundColor: "white",
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft : "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  continueWithPaymentButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  cardDetailsModalLabelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  cardDetailsFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  gridInnerPaddingLeft:{
    padding: "0px !important",
    paddingLeft: "10px !important",
  },
  gridInnerPaddingRight:{
    padding: "0px !important",
    paddingRight: "10px !important",
  },
  gridInnerPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
  },
  cardModalMainTextDescription:{
    fontSize: "14px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: "white",
    textAlign: "center"
  },
  cardModalCptCodeText:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#F8FAFC",
  },
  cardModalPlanPriceDescription:{
    fontSize: "16px",
    fontWeight: 300,
    color: "#94A3B8",
  },
  cardModalPlanPriceText:{
    color:"#F8FAFC",
    fontSize: "48px",
    fontWeight: 500,
    marginBottom: "10px",
  },
  cardModalPlanText:{
    marginBottom: "12px",
    fontSize: "32px",
    fontWeight: 700,
  },
  planBox: {
    height: "50%",
    backgroundColor: '#1E293B',
    color: 'white',
    padding: '150px 50px',
    borderRadius: '8px',
  },
  cardDetailsModalInner:{
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "50px"
  },
  modalCardDetailsInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1200px",
    transform: "Translate(-50%, -50%)",
  },
  okayButton:{
    cursor: 'pointer',
    width:"25%",
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    marginTop: "30px"
  },
  modalDeleteSuccessfullyContext:{
    fontSize: "25px",
    fontWeight: "700",
    color: "#1C1917",
    marginTop: "20px",
  },
  modalDeleteSuccessfully:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"90px 140px",
  },
  cancelButtonFullwodth:{
    cursor: 'pointer',
    backgroundColor: '#DC2626',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    marginLeft: "10px",
    '&:hover': {
      backgroundColor: '#DC2626',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    "@media (max-width:484px)": {
      marginTop: "20px"
    },
  },
  backButtonFullwidth: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#94A3B8',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    marginRight: "10px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
  },
  submitDeleteButtonFullwodth:{
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    marginLeft: "10px",
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    "@media (max-width:484px)": {
      marginTop: "20px"
    },
  },
  cancelDeleteButtonFullwidth: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#94A3B8',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    marginRight: "10px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
  },
  modalDeleteContext:{
    fontSize: 16,
    fontWeight: 400,
    marginBottom: "40px",
    color: "#334155",
  },
  modalDelete:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"70px",
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  modalDeleteInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "700px",
    transform: "Translate(-50%, -50%)",
  },
  cptCodeListBox:{
    width: '100%',
    height: "160px",
    overflowY: "auto",
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "10px",
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cptCodeItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  radioButton: {
    color: '#1799CB',
    '&.Mui-checked': {
      color: '#1799CB', 
    },
    marginBottom: '40px',
  },
  textContainer: {
    flex: 1,
  },
  codeBoxText: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#1E293B',
  },
  descriptionText: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#94A3B8',
  },
  seeCostButton: {
    backgroundColor: '#1799CB',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    padding: "5px 10px",
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
  },
  container: {
    padding: "45px 40px",
    borderRadius: "8px",
    height:'100%',
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ECFAFF",
    height:'100%',
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centerBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  boxWidth: {
    width: "27vw",
    "@media (max-width: 1000px) and (min-width: 700px)": {
      width: "40vw",
    },
    "@media (max-width: 699px)": {
      width: "100%",
    },
  },
  boxMargin: {
    "@media (max-width: 640px)": {
      margin: "0px 40px",
    },
  },
  codeText: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "66px",
    color: "#000",
    marginBottom: "32px",
    textAlign: "center",
  },
  textField: {
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#CBD5E1",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
      },
    },
  },
  errorMessageWidth: {
    width: "100%",
  },
  error: {
    color: "red",
  },
  continueButtonBlue: {
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    backgroundColor: "#1799CB",
    color: "#fff",
    marginTop: "140px",
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#94A3B8",
    },
  },
  continueButtonBlueWithoutMarginTop: {
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    backgroundColor: "#1799CB",
    color: "#fff",
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#94A3B8",
    },
  },
};

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    fontSize: "13px",
    padding: "10px",
    width: "220px",
    color: "#94A3B8",
  },
  arrow: {
    color: "#fff",
  },
})(Tooltip);
// Customizable Area End
