import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, Button } from "@mui/material";
import { imgLogin, imgLogo } from "./assets";
// Customizable Area End

import PrivacyPolicyControllerWeb, {
  Props,
  configJSON,
} from "./PrivacyPolicyController.web";

export default class PrivacyPolicy extends PrivacyPolicyControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { privacyPolicyData } = this.state;
    return (
      <Box sx={styles.policyMainContainer}>
        <Grid container spacing={2} sx={styles.mainGridPolicy}>
          <Grid
            item
            lg={6}
            sx={styles.imageContainerPolicy}
            xl={6}
            xs={12}
            sm={12}
            md={6}
          >
            <Box sx={styles.imageWrapperPolicy}>
              <img src={imgLogin} style={styles.imagePolicy} />
              <Box sx={styles.logoPolicy}>
                <img src={imgLogo} alt="LogoPolicy" />
              </Box>
            </Box>
          </Grid>
          <Grid
            md={6}
            item
            xl={6}
            sx={styles.secondGridPolicy}
            sm={12}
            xs={12}
            lg={6}
          >
            <Box sx={styles.formContainerPolicy}>
              <Typography sx={styles.policyText}>Privacy Policy</Typography>
              <Box sx={styles.scrollbox}>
                {privacyPolicyData?.attributes && (
                  <>
                    <Typography
                      sx={styles.privacyDescriptionText}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: privacyPolicyData.attributes.description,
                      }}
                    />
                    <Box sx={styles.blurOverlay} />
                  </>
                )}
              </Box>
              <Button
                data-test-id="agree-button"
                variant="contained"
                onClick={() => this.handleSignupNavigation()}
                sx={styles.agreePolicyButton}
                fullWidth
              >
                {configJSON.agree}
              </Button>
              <Typography
                data-test-id="back-text"
                component="a"
                onClick={() => this.handleSignupNavigation()}
                sx={styles.backPolicyLink}
              >
                Back
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  blurOverlay: {
    position: 'sticky',
    bottom: 0,
    height: '30%',
    width: '100%',
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    pointerEvents: 'none',
  },
  scrollbox:{
    height:'60%',
    overflowY: 'auto',
    marginBottom:'17px',
    '::-webkit-scrollbar': {
      width: '12px !important',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#CBD5E1 !important',
      borderRadius: '10px !important',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#94A3B8 !important',
      borderRadius: '10px !important',
      transition: 'opacity 0.3s !important',
    },
  },
  privacyDescriptionText:{
    fontFamily: "Helvetica",
    fontSize:"16px",
    lineHeight:"24px",
    color: "#64748B",
    fontWeight: '400',
  },
  policyMainContainer: {
    "@media (max-width:899px)": {
      overflow: "visible",
      padding: "34px",
      height: "100%",
    },
    margin: "34px",
    overflow: "hidden",
    height: "100%",
  },
  backPolicyLink:{
    cursor:'pointer',
    color: "#475569",
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    textDecoration: 'none',
    marginTop:'10px',
    '&:hover': {
      cursor:'pointer',
      color: "#475569",
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      textDecoration: 'none',
      marginTop:'10px',
    },
  },
  agreePolicyButton: {
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
  },
  policyText:{
    fontFamily: "Tiempos Headline",
    fontSize:"30px",
    lineHeight:"40px",
    color: "#1C1917",
    marginBottom: '32px',
    fontWeight: '700',
  },
  formContainerPolicy: {
    alignItems: 'center',
    height: '100%',
    padding: '0 120px',
    "@media (max-width:699px)": {
      padding: '0px',
    },
    "@media (max-width:1199px)": {
      padding: '0 100px',
    },
    "@media (max-width:799px)": {
      padding: '0px 20px',
    },
    "@media (max-width:1099px)": {
      padding: '0 80px',
    },
    "@media (max-width:899px)": {
      marginTop: '20px',
      padding: '0px 40px',
    },
    "@media (max-width:999px)": {
      padding: '0 60px',
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  secondGridPolicy:{
    paddingTop:'0 !important',
    height:"100%",
    paddingLeft:'0 !important',
  },
  logoPolicy: {
    height: '50px',
    position: 'absolute',
    width: '50px',
    left: '25px',
    top: '25px',
  },
  imagePolicy: {
    width: '100%',
    height: '100%',
  },
  imageWrapperPolicy: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainerPolicy: {
    position: 'relative',
    width: '100%',
    paddingTop:'0 !important',
    paddingLeft:'0 !important',
    height: '100%',
  },
  mainGridPolicy: {
    marginLeft: 0,
    width: "100%",
    height: "100%",
    marginTop: 0,
  },
};
// Customizable Area End
