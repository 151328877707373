import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { fieldMappings, handleTokenError } from "../../../components/src/Utility";
import { toast } from "react-toastify";


interface UserDetails {
  id: number;
  activated: boolean;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: number;
  zip_code: string;
  user_pass: string;
  password?: string;
  check_login?: string;
}

interface ResponseJson{
  user_detail: UserDetails
}

interface UpdateProfileValues{
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  zipCode: string;
  phoneNo: number;
}

interface UpdateProfilePost{
  email: string;
  first_name: string;
  last_name: string;
  phone_number: number;
  zip_code: string;
  user_pass: string;
  password?: string;
  check_login?: string;
}

interface ErrorResponse {
  errors: {
      [key: string]: string[] | string;
  } | string[] | string;
}

interface CardAndAddressDetails {
  id: number;
  cardholder_name: string;
  card_number: string;
  expiration_date: string;
  cvv: number;
  country1: string;
  address_line: string;
  city: string;
  state: string;
  zip: string;
  country2: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface CardDetailsFormValues {
  cardholderName: string | undefined;
  cardNumber: string | undefined;
  expiration: string | undefined;
  cvv: string | undefined;
}

interface StripeErrorResponse {
  code: string;
  doc_url: string;
  message: string;
  param: string; 
  request_log_url: string; 
  type: string;
}

interface ResponseData {
  errors?: [];
}

interface ApiResponse {
  errors?: string;
  message: string;
  data: CardAndAddressDetails;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  showPassword: boolean;
  isEmailModelVisible: boolean;
  changedEmail: string;
  seconds: number;
  token: string;
  userDetails: UserDetails
  emailError: string;
  phoneError: string;
  changedValues: Partial<UpdateProfilePost>;
  isEditCardDetailsVisible: boolean;
  isCardModelVisible: boolean;
  isCardAndAddressDetailsFounded: boolean;
  cardAndAddressDetails: CardAndAddressDetails | null;
  cardDetailsFormValues: CardDetailsFormValues | null;
  cardNumberError: string;
  cvvError: string;
  expirationError: string;
  isEditButtonClickedForCard: boolean;
  isCardDetailsUpdatedSuccessfully: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timer: NodeJS.Timeout | null = null;
  apiGetUserProfileDataId: string = "";
  apiPostUpdateProfileDataCallId: string = "";
  apiGetAddressAndCardDetails: string = "";
  apiUpdateCardDetailsCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      tabValue: 0,
      showPassword: false,
      isEmailModelVisible: false,
      changedEmail: "",
      seconds: 60,
      token: "",
      userDetails: {
        id: 0,
        activated: false,
        email: "",
        first_name: "",
        last_name: "",
        phone_number: 0,
        zip_code: "",
        user_pass: "",
      },
      emailError: "",
      phoneError: "",
      changedValues: {},
      isEditCardDetailsVisible: false,
      isCardModelVisible: false,
      isCardAndAddressDetailsFounded: false,
      cardAndAddressDetails: null,
      cardDetailsFormValues: null,
      cardNumberError: "",
      cvvError: "",
      expirationError: "",
      isEditButtonClickedForCard: true,
      isCardDetailsUpdatedSuccessfully: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      handleTokenError(responseJson)

      switch (apiRequestCallId) {
        case this.apiGetUserProfileDataId:
          this.handleSuccess(responseJson);
          break;
    
        case this.apiPostUpdateProfileDataCallId:
          if(responseJson.errors){
            this.handleError(responseJson);
          }
          else{
            this.handleUpdateProfileSuccess();
          }
          break;
    
        case this.apiGetAddressAndCardDetails:
          this.handleGetAddressAndCardDetails(responseJson);
          break;

        case this.apiUpdateCardDetailsCallId:
          this.handleUpdateCardDetails(responseJson);
          break;
    
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.startTimer();
    this.checkTokenFromLocalStorage();
    return Promise.resolve();
  }

  componentWillUnmount(): Promise<void> {
    if (this.timer) {
      clearInterval(this.timer);
    }
    return Promise.resolve();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.seconds === 60 && prevState.seconds !== 60) {
      this.startTimer();
    }
  }

  startTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.tick, 1000);
  };

  tick = () => {
    this.setState((prevState) => {
      if (prevState.seconds === 0) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        return null;
      }
      return { seconds: prevState.seconds - 1 };
    });
  };

  handleError = (responseJson: ErrorResponse) => {
    const { errors } = responseJson;
    let emailError = "";
    let phoneError = "";

    if (typeof errors === 'object' && !Array.isArray(errors)) {
      emailError = this.getFieldError(errors, 'email', configJSON.emailError);
      phoneError = this.getFieldError(errors, 'phone_number', configJSON.phoneNumberError);
    } else if (Array.isArray(errors)) {
      phoneError = this.getArrayError(errors, {
        "Phone number has already been taken": configJSON.phoneNumberError,
        "Phone number Invalid phone number format": "Invalid Mobile No."
      });
    } else if (typeof errors === 'string') {
      emailError = this.getStringError(errors, {
        [configJSON.emailError]: configJSON.emailError,
        "Phone number already taken": configJSON.phoneNumberError
      });
      phoneError = this.getStringError(errors, {
        "Phone number already taken": configJSON.phoneNumberError
      });
    }
    this.setState({ emailError, phoneError });
  }

  handleGetAddressAndCardDetails = (responseJson: ApiResponse) => {
    if (responseJson.errors) {
      this.setState({ isCardAndAddressDetailsFounded: false });
    } else {
      const inputValue = responseJson.data?.card_number.replace(/\s+/g, "") || "";
      const formattedValue = inputValue.match(/.{1,4}/g)?.join(" ") || inputValue;
      const formattedData = {
        ...responseJson.data,
        card_number: formattedValue,
      };
      this.setState({
        isCardAndAddressDetailsFounded: true,
        cardAndAddressDetails: formattedData,
      });
    }
  }

  handleUpdateCardDetails = (responseJson: ResponseData) => {
    if (!responseJson.errors) {
      this.setState({ isEditCardDetailsVisible: false, isEditButtonClickedForCard: true });
      this.handleCardModalClose();
      this.setState({ isCardDetailsUpdatedSuccessfully: true });
    }
  }

  handleCardDetailsUpdatedSuccessfullyModalClose = () => {
    this.setState({ isCardDetailsUpdatedSuccessfully: false });
  }

  getFieldError = (errors: { [key: string]: any }, field: string, errorMsg: string) => {
    if (field in errors && Array.isArray(errors[field])) {
      return errorMsg;
    }
    return "";
  };

  getArrayError = (errors: string[], errorMsgMap: { [key: string]: string }) => {
    for (const [errorText, errorMsg] of Object.entries(errorMsgMap)) {
      if (errors.includes(errorText)) {
        return errorMsg;
      }
    }
    return "";
  };

  getStringError = (errors: string, errorMsgMap: { [key: string]: string }) => {
    return errorMsgMap[errors] || "";
  };

  handleUpdateProfileSuccess = () => {
    this.setState({ emailError: "", phoneError:""});
    if(this.state.changedEmail !== ""){
      this.setState({ isEmailModelVisible: true , seconds: 60 })
    }
    else{
      toast.success("Profile updated successfully")
    }
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.getCurrentUserData(token);
    this.getAddressAndCardDetails(token);
    this.setState({ token });
  };

  getCurrentUserData = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserProfileDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAddressAndCardDetails = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAddressAndCardDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressAndCardDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSuccess = (responseJson: ResponseJson) => {
    this.setState({ userDetails: responseJson.user_detail });
  };

  handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    this.setState({ tabValue: newValue });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleModalClose = () => {
    this.setState({ isEmailModelVisible: false });
  };

  handleCardModalClose = () => {
    this.setState({ isCardModelVisible: false });
  }

  handleOpenCardModel = () => {
    this.setState({ isCardModelVisible: true });
  }

  handleNavigationToLandingPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleFormSubmit = (values: UpdateProfileValues) => {
    const { userDetails: initialValues } = this.state;
    const changedValues: Partial<UpdateProfilePost> = {};

    const fields: Array<keyof UpdateProfileValues> = [
      "firstName",
      "lastName",
      "email",
      "password",
      "zipCode",
      "phoneNo",
    ];

    fields.forEach((field) => {
      const initialFieldKey = fieldMappings[field] || field;
      if (
        values[field] !==
        initialValues[initialFieldKey as keyof UpdateProfilePost]
      ) {
        (changedValues[initialFieldKey as keyof UpdateProfilePost] as
          | string
          | number) = values[field];
      }
    });

    this.processChangedValues(changedValues);
    this.setState({ changedValues: changedValues });
    this.handleSubmit(changedValues);
  };

  processChangedValues = (changedValues: Partial<UpdateProfilePost>) => {
    if ('email' in changedValues && changedValues.email !== undefined) {
      this.setState({ changedEmail: changedValues.email });
    }
    else{
      this.setState({ changedEmail: "" });
    }
  
    if ('user_pass' in changedValues) {
      changedValues['password'] = changedValues['user_pass'];
      changedValues['check_login'] = changedValues['user_pass'];
      delete changedValues['user_pass'];
    }
  };

  handleSubmit = (changedValues: Partial<UpdateProfilePost>) => {
    const formData = new FormData();

    Object.entries(changedValues).forEach(([key, value]) => {
      formData.append(key, (value ?? '').toString());
    });    

    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostUpdateProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editCardDetails = () => {
    this.setState((prevState) => ({ isEditCardDetailsVisible: !prevState.isEditCardDetailsVisible }));
    this.setState((prevState) => ({ isEditButtonClickedForCard: !prevState.isEditButtonClickedForCard }));
  };

  handleCardDetailsUpdate = (values : CardDetailsFormValues) => {
    const formattedValues = {
      ...values,
      cardNumber: values?.cardNumber?.replace(/\s+/g, ""),
    };
    this.createStripeToken(formattedValues);
  };

  createStripeToken = async (values: CardDetailsFormValues) => {
    const [expMonth, expYear] = (values.expiration || "").split('/');
    const formData = new URLSearchParams();
    formData.append('card[number]', values?.cardNumber || "");
    formData.append('card[exp_month]', expMonth);
    formData.append('card[exp_year]', expYear);
    formData.append('card[cvc]', values?.cvv || "");
  
    await fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${configJSON.publishment}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.handleStripeTokenGenerationError(data.error);
        } else {
          this.setState({ cardDetailsFormValues: values });
          this.handleOpenCardModel();
        }
      });
  };

  handleStripeTokenGenerationError = (error: StripeErrorResponse) => {
    switch (error.code) {
      case 'incorrect_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_expiry_month':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_expiry_year':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_cvc':
        this.setState({ cvvError : error.message });
        break;
      default:
        break;
    }
  };

  onCardNumberChange = () => {
    this.setState({ cardNumberError: '' });
  };

  onExpirationChange = () => {
    this.setState({ expirationError: '' });
  };

  onCvvChange = () => {
    this.setState({ cvvError: '' });
  };

  handleCardUpdateConfirm = () => {
    const formData = new FormData();
    const { cardDetailsFormValues, token } = this.state;
    
    formData.append("cardholder_name", cardDetailsFormValues?.cardholderName || "");
    formData.append("card_number", cardDetailsFormValues?.cardNumber || "");
    formData.append("expiration_date", cardDetailsFormValues?.expiration || "");
    formData.append("cvv", cardDetailsFormValues?.cvv || "");

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateCardDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCardAndAddressDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
