import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  Box,
  Tabs,
  Tab,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
  Grid,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { imgCloseButton, imgCloseModal, imgPlusButton, imgCheck, imgCard, imgOk } from "./assets";
import { Chart } from "react-google-charts";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getErrorAndHelperText, validateExpirationYear, validateExpirationMonth } from "../../../components/src/Utility";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}
// Customizable Area End

import LandingPagePaymentControllerWeb, {
  Props,
  configJSON,
} from "./LandingPagePaymentController.web";

export default class LandingPagePayment extends LandingPagePaymentControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };

  renderIf = <P,>(condition: boolean, component: React.ReactElement<P>) => {
    return condition ? component : null;
  };

  renderConditionalComponent = <P, Q>(
    condition: boolean,
    trueComponent: React.ReactElement<P>,
    falseComponent: React.ReactElement<Q>
  ) => {
    return condition ? trueComponent : falseComponent;
  };
  
  moreInfoData= [
    {
      id:1,
      desciption:"Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    },
    {
      id:2,
      desciption:"Horem ipsum dolor sit amet consectetur",
    },
    {
      id:3,
      desciption:"Norem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id:4,
      desciption:"Horem ipsum dolor sit amet consectetur",
    },
  ]

  validationSchemaForCardDetails = Yup.object({
    cardholderName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Cardholder Name should only contain letters")
    .required('Cardholder Name is required'),
    expiration: Yup.string()
    .test('valid-expiration', 'Invalid format. Use MM/YYYY.', validateExpirationYear)
    .test('month-range', 'Month must be between 01 and 12.', validateExpirationMonth)
    .required('Expiration is required'),
    cardNumber: Yup.string()
    .matches(/^[\d\s]+$/, "Card Number must contain only numbers")
    .required("Card Number is required"),
    cvv: Yup.string()
    .matches(/^\d+$/, 'CVV must contain only numbers')
    .required('CVV is required'),
  });

  getInitialValuesForCardDetails() {
    const{ cardDetailsFromGet } = this.state;
    return {
      cardholderName: cardDetailsFromGet.cardholder_name,
      cardNumber: cardDetailsFromGet.card_number,
      expiration: cardDetailsFromGet.expiration_date,
      cvv: `${cardDetailsFromGet.cvv}`,
    };
  }

  capitalizeFirstLetter = (text : string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      tabValue,
      tabInnerValue,
      isTableModalOpen,
      cptCodeDataResponse,
      simmiliarCptCodeRecord,
      isPremiumPlan,
      currentPage,
      itemsPerPage,
      totalRecords,
      premiumPlan,
      isCardDetailsModal,
      planById,
      cardNumberError,
      expirationError,
      cvvError,
      isPaymentDone
    } = this.state;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const getTabStyles = ( isSelected:boolean ) => ({
      ...styles.tab,
      fontWeight: isSelected ? 600 : 500,
      borderBottom: isSelected ? "3px solid #1799CB" : "3px solid #E2E8F0"
    });
    const initialValuesForCardDetails = this.getInitialValuesForCardDetails();
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            {this.renderIf(
              tabValue === 0,
              <Box sx={styles.header}>
                <Box width={"50%"}>
                  <Typography variant="h2" sx={styles.title}>
                    {configJSON.costRelatedTo}
                  </Typography>
                  <Typography variant="h3" sx={styles.subTitle}>
                    Yorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis.
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h3" sx={styles.cityName}>
                    Union City, 11101
                  </Typography>
                  <Typography variant="h3" sx={styles.percentileText}>
                    *Estimates are based on the 50th and 90th percentiles.
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={
                tabValue === 0
                  ? styles.contentBox
                  : styles.contentBoxForNextTabs
              }
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  aria-label="tabs example"
                  data-test-id="tabs"
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab
                    label="Local Cost"
                    sx={{ ...styles.tabRoot, ...styles.firstTab }}
                  />
                  <Tab
                    label="Year Over Year Comparison"
                    sx={styles.tabRoot}
                    disabled={!isPremiumPlan}
                  />
                  <Tab
                    label="More Information"
                    sx={{ ...styles.tabRoot, ...styles.lastTab }}
                  />
                </Tabs>
              </Box>
              {this.renderIf(
                tabValue === 0,
                <>
                  <TabContext value={tabInnerValue}>
                    <Box sx={styles.innerTab}>
                      <TabList
                        data-test-id="innerTabs"
                        onChange={this.handleInnerTabChange}
                      >
                        <Tab
                          label="In-Network Price"
                          value="1"
                          sx={getTabStyles(tabInnerValue === "1")}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box sx={styles.tabPanel1}>
                        <TableContainer sx={styles.tableContainer}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                  }}
                                >
                                  Primary Medical Procedure
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    position: "relative",
                                    "&::after": {
                                      content: '""',
                                      position: "absolute",
                                      top: "50%",
                                      right: 0,
                                      height: "50%",
                                      borderRight: "1px solid #ccc",
                                      transform: "translateY(-50%)",
                                    },
                                  }}
                                  align="center"
                                >
                                  Average
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    position: "relative",
                                    "&::after": {
                                      content: '""',
                                      position: "absolute",
                                      top: "50%",
                                      right: 0,
                                      height: "50%",
                                      borderRight: "1px solid #ccc",
                                      transform: "translateY(-50%)",
                                    },
                                  }}
                                  align="center"
                                >
                                  {configJSON.fiftyPercentile}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                  }}
                                  align="center"
                                >
                                  90th percentiles
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={{ ...styles.tableBodyCell, width: "50%" }}
                                >
                                  <Box component="span" display="block">
                                    CPT CODE-{" "}
                                    {cptCodeDataResponse?.cpt_code_value}
                                  </Box>
                                  <Box
                                    component="span"
                                    display="block"
                                    sx={styles.innerBoxText}
                                  >
                                    {
                                      cptCodeDataResponse?.procedure_code_description
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.average}
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.fifty_percentile_value}
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.ninty_percentile_value}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        paddingTop={"20px"}
                      >
                        {this.renderConditionalComponent(
                          isPremiumPlan,
                          <Button
                            type="button"
                            variant="contained"
                            sx={styles.simmiliarButton}
                            data-test-id="simmiliar-button"
                            onClick={() => this.handleSimmiliarCptCodeButton()}
                          >
                            View Similar CPT Codes
                          </Button>,
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"baseline"}
                            width={"100%"}
                          >
                            <Typography sx={styles.otherCptCodeText}>
                              To Check the Other CPT code Subscribe to Our
                              Premium Plan
                            </Typography>
                            <Box display={"flex"}>
                              <Typography sx={styles.planPriceModal}>
                                ${premiumPlan.price}
                              </Typography>
                              <Typography sx={styles.planPriceUnitModal}>
                                /month
                              </Typography>
                              <Box display={"flex"} marginLeft={"25px"}>
                                <Button
                                  type="button"
                                  variant="contained"
                                  sx={{
                                    ...styles.simmiliarButton,
                                    width: "200px",
                                  }}
                                  fullWidth
                                  onClick={() =>
                                    this.handleOpenCardDetailsModel(
                                      premiumPlan.id
                                    )
                                  }
                                  data-test-id="buyButtonPremium"
                                >
                                  Buy
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Modal
                        open={isTableModalOpen}
                        onClose={this.handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styles.modalInner}>
                          <Box
                            display={"flex"}
                            justifyContent={"end"}
                            marginBottom={"10px"}
                          >
                            <img
                              src={imgCloseModal}
                              style={styles.closeIcon}
                              data-test-id="close-icon"
                              onClick={() => this.handleModalClose()}
                            />
                          </Box>
                          <Box sx={styles.modal}>
                            <TableContainer sx={styles.tableContainer}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        ...styles.tableHeadCell,
                                        borderBottomLeftRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                      }}
                                    >
                                      Primary Medical Procedure
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        position: "relative",
                                        "&::after": {
                                          content: '""',
                                          position: "absolute",
                                          top: "50%",
                                          right: 0,
                                          height: "50%",
                                          borderRight: "1px solid #ccc",
                                          transform: "translateY(-50%)",
                                        },
                                      }}
                                    >
                                      Average
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        position: "relative",
                                        "&::after": {
                                          content: '""',
                                          position: "absolute",
                                          top: "50%",
                                          right: 0,
                                          height: "50%",
                                          borderRight: "1px solid #ccc",
                                          transform: "translateY(-50%)",
                                        },
                                      }}
                                    >
                                      50th percentiles
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        borderBottomRightRadius: "8px",
                                        borderTopRightRadius: "8px",
                                      }}
                                    >
                                      90th percentiles
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {simmiliarCptCodeRecord.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          ...styles.tableModalBodyCell,
                                          width: "45%",
                                        }}
                                      >
                                        <Box component="span" display="block">
                                          CPT CODE- {`${row.cpt_code_value}`}
                                        </Box>
                                        <Box
                                          component="span"
                                          display="block"
                                          sx={styles.innerBoxTextModal}
                                        >
                                          {row.procedure_description}
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row.average}
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row.fifty_percentile}
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row.ninty_percentile}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {totalRecords > 5 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "16px",
                                }}
                              >
                                <Box width={"50%"} display={"flex"}>
                                  <Button
                                    variant="contained"
                                    onClick={this.handlePrevious}
                                    disabled={currentPage === 1}
                                    data-test-id="previousButton"
                                    sx={styles.previousAndNextButton}
                                    fullWidth
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={this.handleNext}
                                    disabled={currentPage === totalPages}
                                    data-test-id="nextButton"
                                    sx={{
                                      ...styles.previousAndNextButton,
                                      marginLeft: "10px",
                                    }}
                                    fullWidth
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Modal>
                    </TabPanel>
                  </TabContext>
                  <Modal
                    open={isCardDetailsModal}
                    data-test-id="cardDetailsModalClose"
                    onClose={this.handleCardDetailsModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      sx: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    <Box sx={styles.modalCardDetailsInner}>
                      <Box
                        display={"flex"}
                        justifyContent={"end"}
                        marginBottom={"10px"}
                      >
                        <img
                          src={imgCloseModal}
                          style={styles.closeDeleteIcon}
                          data-test-id="cardDetailsModalCloseButton"
                          onClick={() => this.handleCardDetailsModalClose()}
                        />
                      </Box>
                      <Box sx={styles.cardDetailsModalInner}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Box sx={styles.planBox}>
                              <Typography
                                sx={{
                                  ...styles.cardModalPlanText,
                                  color: "#F69544",
                                }}
                              >
                                {this.capitalizeFirstLetter(planById.name)}
                              </Typography>
                              <Typography sx={styles.cardModalPlanPriceText}>
                                ${planById.price}
                              </Typography>
                              <Typography
                                sx={styles.cardModalPlanPriceDescription}
                              >
                                Up to{" "}
                                <span style={styles.cardModalCptCodeText}>
                                  Unlimited CPT Code
                                </span>
                              </Typography>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                marginTop={"50px"}
                              >
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  marginBottom={"8px"}
                                >
                                  <img src={imgCheck} />
                                  <Typography
                                    sx={styles.cardModalMainTextDescription}
                                  >
                                    Access to Year Over Year Comparison.
                                  </Typography>
                                </Box>
                                <Box display={"flex"} alignItems={"center"}>
                                  <img src={imgCheck} />
                                  <Typography
                                    sx={styles.cardModalMainTextDescription}
                                  >
                                    Access to Similar CPT Codes.
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Formik
                              key={`${initialValuesForCardDetails.cardNumber}-${initialValuesForCardDetails.cardholderName}-${initialValuesForCardDetails.cvv}-${initialValuesForCardDetails.expiration}`}
                              initialValues={initialValuesForCardDetails}
                              validationSchema={
                                this.validationSchemaForCardDetails
                              }
                              onSubmit={(values) =>
                                this.handleCardDetailsSubmit(values)
                              }
                            >
                              {({
                                errors,
                                touched,
                                isValid,
                                values,
                                handleChange,
                              }) => (
                                <Form>
                                  <Box padding="0px 40px">
                                    <Grid
                                      container
                                      sx={styles.gridInnerPadding}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sx={styles.gridInnerPadding}
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          marginBottom={"30px"}
                                        >
                                          <Typography
                                            sx={styles.billingHeader}
                                            textAlign="center"
                                          >
                                            Add Card Details
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={styles.gridInnerPadding}
                                      >
                                        <FormControl
                                          sx={styles.cardDetailsFormControl}
                                        >
                                          <FormLabel
                                            sx={
                                              styles.cardDetailsModalLabelText
                                            }
                                          >
                                            Cardholder Name
                                          </FormLabel>
                                          <Field
                                            as={TextField}
                                            name="cardholderName"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Enter Name"
                                            fullWidth
                                            sx={styles.cardModalFieldStyle}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <img src={imgCard} />
                                                </InputAdornment>
                                              ),
                                            }}
                                            error={
                                              getErrorAndHelperText(
                                                "cardholderName",
                                                errors as FormError,
                                                touched as FormErrorTouched
                                              ).isError
                                            }
                                            helperText={
                                              getErrorAndHelperText(
                                                "cardholderName",
                                                errors as FormError,
                                                touched as FormErrorTouched
                                              ).helperText
                                            }
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={styles.gridInnerPadding}
                                      >
                                        <FormControl
                                          sx={styles.cardDetailsFormControl}
                                        >
                                          <FormLabel
                                            sx={
                                              styles.cardDetailsModalLabelText
                                            }
                                          >
                                            Card Number
                                          </FormLabel>
                                          <Field
                                            as={TextField}
                                            name="cardNumber"
                                            variant="outlined"
                                            type="text"
                                            data-test-id="cardNumberTextField"
                                            placeholder="Enter Card Number"
                                            fullWidth
                                            value={values.cardNumber}
                                            onChange={(
                                              event: React.ChangeEvent<
                                                HTMLInputElement
                                              >
                                            ) => {
                                              this.onCardNumberChange();
                                              const inputValue = event.target.value.replace(
                                                /\s+/g,
                                                ""
                                              );
                                              const formattedValue =
                                                inputValue
                                                  .match(/.{1,4}/g)
                                                  ?.join(" ") || inputValue;
                                              handleChange("cardNumber")({
                                                ...event,
                                                target: {
                                                  ...event.target,
                                                  value: formattedValue,
                                                },
                                              });
                                            }}
                                            helperText={
                                              getErrorAndHelperText(
                                                "cardNumber",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                cardNumberError
                                              ).helperText
                                            }
                                            sx={styles.cardModalFieldStyle}
                                            error={
                                              getErrorAndHelperText(
                                                "cardNumber",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                cardNumberError
                                              ).isError
                                            }
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sx={styles.gridInnerPaddingRight}
                                      >
                                        <FormControl
                                          sx={styles.cardDetailsFormControl}
                                        >
                                          <FormLabel
                                            sx={
                                              styles.cardDetailsModalLabelText
                                            }
                                          >
                                            Expiration
                                          </FormLabel>
                                          <Field
                                            as={TextField}
                                            name="expiration"
                                            variant="outlined"
                                            type="text"
                                            placeholder="MM/YYYY"
                                            fullWidth
                                            data-test-id="expirationTextField"
                                            value={values.expiration}
                                            onChange={(
                                              event: React.ChangeEvent<
                                                HTMLInputElement
                                              >
                                            ) => {
                                              handleChange("expiration")(event);
                                              this.onExpirationChange();
                                            }}
                                            sx={styles.cardModalFieldStyle}
                                            error={
                                              getErrorAndHelperText(
                                                "expiration",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                expirationError
                                              ).isError
                                            }
                                            helperText={
                                              getErrorAndHelperText(
                                                "expiration",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                expirationError
                                              ).helperText
                                            }
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sx={styles.gridInnerPaddingLeft}
                                      >
                                        <FormControl
                                          sx={styles.cardDetailsFormControl}
                                        >
                                          <FormLabel
                                            sx={
                                              styles.cardDetailsModalLabelText
                                            }
                                          >
                                            CVV
                                          </FormLabel>
                                          <Field
                                            as={TextField}
                                            variant="outlined"
                                            name="cvv"
                                            placeholder="Enter CVV"
                                            type="password"
                                            fullWidth
                                            data-test-id="cvvTextField"
                                            value={values.cvv}
                                            onChange={(
                                              event: React.ChangeEvent<
                                                HTMLInputElement
                                              >
                                            ) => {
                                              handleChange("cvv")(event);
                                              this.onCvvChange();
                                            }}
                                            helperText={
                                              getErrorAndHelperText(
                                                "cvv",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                cvvError
                                              ).helperText
                                            }
                                            error={
                                              getErrorAndHelperText(
                                                "cvv",
                                                errors as FormError,
                                                touched as FormErrorTouched,
                                                cvvError
                                              ).isError
                                            }
                                            sx={{
                                              ...styles.cardModalFieldStyle,
                                              "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                                "&::-webkit-outer-spin-button": {
                                                  "-webkit-appearance": "none",
                                                  margin: 0,
                                                },
                                                "&::-webkit-inner-spin-button": {
                                                  "-webkit-appearance": "none",
                                                  margin: 0,
                                                },
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={styles.gridInnerPadding}
                                      >
                                        <Button
                                          type="submit"
                                          variant="contained"
                                          sx={styles.continueWithPaymentButton}
                                          fullWidth
                                          disabled={!isValid}
                                        >
                                          Continue with Payment
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Form>
                              )}
                            </Formik>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Modal>
                  <Modal
                    open={isPaymentDone}
                    data-test-id="paymentDoneModalClose"
                    onClose={() => this.handlePaymentDoneModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      sx: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    <Box sx={styles.skipModalPlanInner}>
                      <Box sx={styles.skipModalInner}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <Typography sx={styles.skipModalHeaderText}>
                            Congratulation! Your Payment has been Completed.
                          </Typography>
                          <Box marginTop={"50px"}>
                            <img src={imgOk} />
                          </Box>
                          <Typography sx={styles.skipDescription}>
                            Qorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc vulputate libero et velit interdum, ac
                            aliquet odio mattis. Class aptent taciti sociosqu .
                          </Typography>
                          <Button
                            type="submit"
                            variant="contained"
                            data-test-id="paymentDoneModal"
                            sx={styles.skipModalButton}
                            onClick={() => this.handlePaymentDoneModalClose()}
                          >
                            Okay
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </>
              )}
              {this.renderIf(
                tabValue === 1,
                <Box sx={styles.yearOverYearBox}>
                  {cptCodeDataResponse?.chart_data && (
                    <Chart
                      width={"100%"}
                      height={"450px"}
                      chartType="LineChart"
                      data={this.getChartData(cptCodeDataResponse.chart_data)}
                      options={{
                        legend: { position: "bottom" },
                        vAxis: {
                          gridlines: {
                            color: "transparent",
                            count: 10,
                          },
                          baselineColor: "#655B96",
                          baselineWidth: 2,
                        },
                        hAxis: {
                          minValue: Math.min(
                            ...Object.keys(cptCodeDataResponse.chart_data).map(
                              Number
                            )
                          ),
                          maxValue: Math.max(
                            ...Object.keys(cptCodeDataResponse.chart_data).map(
                              Number
                            )
                          ),
                          ticks: Object.keys(
                            cptCodeDataResponse.chart_data
                          ).map(Number),
                          gridlines: {
                            color: "transparent",
                          },
                          baselineColor: "#655B96",
                          baselineWidth: 2,
                          format: "####",
                        },
                        annotations: {
                          stem: {
                            color: "#655B96",
                          },
                          textStyle: {
                            opacity: 0,
                          },
                          style: "line",
                        },
                        series: {
                          0: { color: "blue" },
                          1: { color: "red" },
                          2: { color: "green" },
                        },
                        pointSize: 5,
                        focusTarget: "category",
                      }}
                    />
                  )}
                </Box>
              )}
              {this.renderIf(
                tabValue === 2,
                <Box sx={styles.moreInfoBox}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={"4px 0px 36px"}
                  >
                    <Typography sx={styles.moreInfoText}>
                      Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Typography>
                    <img src={imgPlusButton} />
                  </Box>
                  <Divider />
                  <Box display="flex" alignItems="center" padding={"36px 0px"}>
                    <Box flex={1}>
                      <Typography sx={styles.moreInfoText}>
                        Norem ipsum dolor sit amet, consectetur adipiscing elit.
                      </Typography>
                    </Box>
                    <Box flex={2} sx={{ paddingLeft: "16px" }}>
                      <Typography sx={styles.moreInfoDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        id venenatis pretium risus euismod dictum egestas orci
                        netus feugiat ut egestas ut sagittis tincidunt phasellus
                        elit etiam cursus orci in. Id sed montes.
                      </Typography>
                    </Box>
                    <Box sx={{ paddingLeft: "16px" }}>
                      <img src={imgCloseButton} />
                    </Box>
                  </Box>
                  <Divider />
                  {this.moreInfoData.map((row) => (
                    <>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        padding={"36px 0px"}
                        key={row.id}
                      >
                        <Typography sx={styles.moreInfoText}>
                          {row.desciption}
                        </Typography>
                        <img src={imgPlusButton} />
                      </Box>
                      <Divider />
                    </>
                  ))}
                </Box>
              )}
            </Box>
            {this.renderIf(
              tabValue === 0,
              <Box sx={styles.footer}>
                <Typography sx={styles.footerHeadText}>
                  <b>
                    * <span style={{ marginRight: "30px" }}>N/A</span>Means that
                    our database does not have enough data to provide a cost
                    estimate for this procedure code in this geographic area.
                  </b>
                </Typography>
                <Typography sx={styles.footerText}>
                  FAIR Health's cost estimates only apply to people who have
                  private insurance, or who are uninsured. Our cost estimates
                  don't include government programs like Medicare, Medicaid or
                  military plans like Tricare. But, they may be helpful if
                  you're comparing costs for Medicare Advantage Plans, where you
                  often pay more if you go outside your plan network.
                </Typography>
                <Typography sx={{ ...styles.footerText, padding: 0 }}>
                  Current Procedural Terminology Codes ("CPT") displayed on the
                  site are owned and copyrighted by the American Medical
                  Association ("AMA") and licensed to FAIR Health.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  CPT copyright 2023 American Medical Association.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  FAIR Health uses commercial claims data and data from the
                  Medicare Qualified Entity Certification Program to create
                  benchmarks. Only commercial in-network rates are used to
                  calculate the in-network price.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  <Box component="span" display="block">
                    Prices are based on FH<sup>®</sup>
                  </Box>
                  <Box component="span" display="block">
                    Benchmarks
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  cardModalCptCodeText:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#F8FAFC",
  },
  cardModalPlanPriceDescription:{
    fontSize: "16px",
    fontWeight: 300,
    color: "#94A3B8",
  },
  cardModalPlanPriceText:{
    color:"#F8FAFC",
    fontSize: "48px",
    fontWeight: 500,
    marginBottom: "10px",
  },
  cardModalPlanText:{
    marginBottom: "12px",
    fontSize: "32px",
    fontWeight: 700,
  },
  planBox: {
    height: "50%",
    backgroundColor: '#1E293B',
    color: 'white',
    padding: '150px 50px',
    borderRadius: '8px',
  },
  cardModalMainTextDescription:{
    fontSize: "14px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: "white",
    textAlign: "center"
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  cardDetailsModalInner:{
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "50px"
  },
  modalCardDetailsInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1200px",
    transform: "Translate(-50%, -50%)",
  },
  skipModalButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    width: "35%",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
  },
  skipDescription:{
    fontSize: "24px",
    fontWeight: 400,
    color: "#64748B",
    textAlign: "center",
    marginTop: "50px",
  },
  skipModalHeaderText:{
    fontSize: "30px",
    fontWeight: 700,
    color: "black",
    textAlign: "center",
  },
  skipModalInner:{
    borderRadius: "6px",
    backgroundColor: "#F9F9FF",
    padding:"80px 175px",
  },
  skipModalPlanInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1100px",
    transform: "Translate(-50%, -50%)",
  },
  billingHeader:{
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  cardModalFieldStyle:{
    backgroundColor: "#fff",
    '& .MuiFormHelperText-root': {
      margin: 0,
      backgroundColor: "white",
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft : "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  cardDetailsModalLabelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  cardDetailsFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  gridInnerPaddingLeft:{
    padding: "0px !important",
    paddingLeft: "10px !important",
  },
  gridInnerPaddingRight:{
    padding: "0px !important",
    paddingRight: "10px !important",
  },
  gridInnerPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
  },
  continueWithPaymentButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  otherCptCodeText:{
    color:"#78716C",
    fontWeight:"600",
    fontSize:"15px",
  },
  planPriceModal: {
    fontWeight: 500,
    fontSize: "32px",
    color: "#1E293B",
    marginLeft:"15px"
  },
  planPriceUnitModal: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    marginTop: "15px",
  },
  moreInfoDescription:{
    color:"#94A3B8",
    fontSize:"14px",
    fontWeight:"400",
  },
  moreInfoText:{
    color:"#1E293B",
    fontWeight:"500",
    fontSize:"18px",
  },
  yearOverYearBox:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop: "26px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid #D4D2E3",
  },
  moreInfoBox:{
    height: '70%',
    overflowY: "auto",
    marginTop: "26px",
    padding: "32px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid #D4D2E3",
    '::-webkit-scrollbar': {
      width: '12px !important',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#CBD5E1 !important',
      borderRadius: '10px !important',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#94A3B8 !important',
      borderRadius: '10px !important',
      transition: 'opacity 0.3s !important',
    },
  },
  tableModalBodyCell:{
    padding: "16px",
    color: '#1E293B',
    fontSize: "18px",
    fontWeight: '700',
  },
  modal:{
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding:"8px",
  },
  closeIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  modalInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1284px",
    transform: "Translate(-50%, -50%)",
  },
  footerText:{
    color: '#64748B',
    fontSize: '14px',
    fontWeight: '400',
    padding: "20px 0px",
    width:"50%",
  },
  footerHeadText:{
    color: '#64748B',
    fontSize: '14px',
    fontWeight: '500',
    width:"50%",
  },
  footer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: "60px",
  },
  simmiliarButton:{
    fontSize: "16px",
    fontWeight: "500",
    cursor: 'pointer',
    backgroundColor: '#F69544',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F69544',
    },
  },
  previousAndNextButton:{
    fontSize: "16px",
    fontWeight: "500",
    cursor: 'pointer',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    backgroundColor: '#1799CB',
    '&:hover': {
      cursor:'pointer',
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  innerBoxText:{
    color: '#94A3B8',
    fontSize: '18px',
    fontWeight: '400',
  },
  innerBoxTextModal:{
    color: '#64748B',
    fontSize: '18px',
    fontWeight: '400',
  },
  tableBodyCell:{
    padding: "40px 22px 24px",
    color: '#1E293B',
    fontSize: "18px",
    fontWeight: '700',
    borderBottom: 'none',
  },
  tableHeadCell:{
    padding: "25px 20px 20px",
    backgroundColor: '#334155',
    color: '#FFFFFF',
    fontSize: "20px",
    fontWeight: '600',
  },
  tableContainer:{
    width: '100%',
  },
  tabPanel1:{
    padding:"8px !important",
    backgroundColor: '#fff !important',
  },
  tab:{
    padding: "0px 10px 0px !important",
    textTransform: "none",
    fontSize: '20px',
  },
  innerTab:{
    paddingTop: "38px",
    paddingBottom: "24px",
  },
  tabRoot: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding:'16px 45px 16px 45px',
    fontWeight: 600,
    fontSize: '15px',
    backgroundColor: '#fff',
    '&.Mui-selected': {
      backgroundColor: '#1799CB',
      color: '#ffffff',
      borderRadius: '25px',
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#fff',
      color: '#94A3B8',
    },
  },
  firstTab: {
    borderRadius: '25px 0 0 25px',
  },
  lastTab: {
    borderRadius: '0 25px 25px 0',
  },
  contentBox:{
    backgroundColor: "#ECFAFF",
    padding: "40px 40px 0px",
    borderRadius: "0px 0px 16px 16px !important",
  },
  contentBoxForNextTabs:{
    backgroundColor: "#ECFAFF",
    padding: "40px",
    borderRadius: "16px !important",
  },
  container: {
    padding: "40px",
    borderRadius: "16px", 
  },
  innerContainer: {
    height:'100%',
  },
  header:{
    padding: "30px",
    backgroundColor: "#1E293B !important",
    borderRadius: "16px 16px 0px 0px",
  },
  title:{
    color: "#ffffff",
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "8px",
  },
  subTitle:{
    color: "#D1D5DB",
    fontSize: "14px",
    fontWeight: "300",
    marginBottom: "8px",
  },
  cityName:{
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "italic",
    alignSelf: "flex-start",
  },
  percentileText:{
    color: "#ffffff",
    fontSize: "13px",
    fontWeight: "400",
    fontStyle: "italic",
    alignSelf: "flex-end",
  },
}
// Customizable Area End
