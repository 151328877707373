export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgLogin = require("../assets/image_login.png");
export const imgLogo = require("../assets/image_logo.png");
export const imgCancle = require("../assets/image_cancle.png");
export const imgCheck = require("../assets/image_check.png");
export const imgCheckGrey = require("../assets/image_check_grey.png");
export const imgCheckMark = require("../assets/image_check_mark.png");
export const imgClose = require("../assets/image_close.png");
export const imgCard = require("../assets/image_card.png");
export const imgOk = require("../assets/image_ok.png");
