import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, TextField, FormControl, FormLabel, Button } from "@mui/material";
import { imgLogin, imgLogo } from "./assets";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getErrorAndHelperText } from "../../../components/src/Utility";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}
// Customizable Area End

import ForgotPasswordBlockControllerWeb, {
  Props,
  configJSON,
} from "./ForgotPasswordBlockController.web";

export default class ForgotPasswordBlock extends ForgotPasswordBlockControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getInitialValues() {
    return {
      email:'',
    };
  }

  validationSchema = Yup.object().shape({
    email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { emailError } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <Box sx={styles.forgotPasswordMainContainer}>
        <Grid container spacing={2} sx={styles.mainGridForgotPassword}>
          <Grid
            item
            sx={styles.imageContainerForgotPassword}
            lg={6}
            xl={6}
            sm={12}
            xs={12}
            md={6}
          >
            <Box sx={styles.imageWrapperForgotPassword}>
              <img
                src={imgLogin}
                alt={configJSON.loginAlt}
                style={styles.imageForgotPassword}
              />
              <Box sx={styles.logoForgotPassword}>
                <img src={imgLogo} alt="LogoForgotPassword" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xl={6}
            lg={6}
            xs={12}
            sx={styles.secondGridForgotPassword}
          >
            <Box sx={styles.formContainerForgotPassword}>
              <Typography sx={styles.ForgotPasswordText}>
                Password recovery
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Typography sx={styles.ForgotPasswordTextInner}>
                  Forgot password?
                </Typography>
                <Typography sx={styles.ForgotPasswordTextSendLink}>
                  No worries, we'll send a link to your register Email ID.
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={(values) => {
                    this.handleSubmit(values);
                  }}
                >
                  {({ errors, touched, isValid }) => (
                    <Form style={styles.ForgotPasswordForm}>
                      <FormControl
                        sx={styles.forgotPasswordFormControlPassword}
                      >
                        <FormLabel sx={styles.labelTextForgotPassword}>
                          Email ID
                        </FormLabel>
                        <Field
                          as={TextField}
                          name="email"
                          variant="outlined"
                          type="email"
                          placeholder="Enter Email ID"
                          fullWidth
                          sx={styles.fieldStyleForgotPassword}
                          error={
                            getErrorAndHelperText(
                              "email",
                              errors as FormError,
                              touched as FormErrorTouched,
                              emailError
                            ).isError
                          }
                          helperText={
                            getErrorAndHelperText(
                              "email",
                              errors as FormError,
                              touched as FormErrorTouched,
                              emailError
                            ).helperText
                          }
                        />
                      </FormControl>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={styles.nextButton}
                        fullWidth
                        disabled={!isValid}
                      >
                        Next
                      </Button>
                      <Box sx={styles.boxCenter}>
                      <Typography sx={styles.signupText}>
                        {configJSON.dontHaveAnAccount}{" "}
                        <Typography
                          component="a"
                          data-test-id="signup-text"
                          sx={styles.signupLink}
                          onClick={() => this.handleSignupNavigation()}
                        >
                          {configJSON.signUp}
                        </Typography>
                      </Typography>
                    </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  signupLink:{
    cursor:'pointer',
    color: "#1799CB",
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    textDecoration: 'none',
    '&:hover': {
      cursor:'pointer',
      color: "#1799CB",
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      textDecoration: 'none',
    },
  },
  signupText:{
    color: "#0F172A",
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  boxCenter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'10px',
  },
  nextButton: {
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  labelTextForgotPassword:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  fieldStyleForgotPassword:{
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft:"5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  forgotPasswordFormControlPassword:{
    width: '100%',
    marginBottom: '30px',
  },
  ForgotPasswordForm:{
    width: '100%',
  },
  ForgotPasswordTextSendLink:{
    fontSize:"16px",
    lineHeight:"24px",
    color: "#334155",
    fontWeight: '400',
    marginBottom: '30px',
  },
  ForgotPasswordTextInner:{
    fontSize:"18px",
    lineHeight:"26px",
    color: "#334155",
    marginBottom: '20px',
    fontWeight: '700',
    alignSelf: 'flex-start',
  },
  ForgotPasswordText:{
    fontFamily: "Tiempos Headline",
    fontSize:"30px",
    lineHeight:"40px",
    color: "#1C1917",
    marginBottom: '30px',
    fontWeight: '700',
  },
  formContainerForgotPassword: {
    alignItems: 'center',
    height: '100%',
    padding: '0 120px',
    "@media (max-width:699px)": {
      padding: '0px',
    },
    "@media (max-width:1199px)": {
      padding: '0 100px',
    },
    "@media (max-width:799px)": {
      padding: '0px 20px',
    },
    "@media (max-width:1099px)": {
      padding: '0 80px',
    },
    "@media (max-width:899px)": {
      marginTop: '20px',
      padding: '0px 40px',
    },
    "@media (max-width:999px)": {
      padding: '0 60px',
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  secondGridForgotPassword:{
    paddingTop:'0 !important',
    height:"100%",
    paddingLeft:'0 !important',
  },
  logoForgotPassword: {
    height: '50px',
    position: 'absolute',
    width: '50px',
    left: '25px',
    top: '25px',
  },
  imageForgotPassword: {
    width: '100%',
    height: '100%',
  },
  imageWrapperForgotPassword: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainerForgotPassword: {
    position: 'relative',
    width: '100%',
    paddingTop:'0 !important',
    paddingLeft:'0 !important',
    height: '100%',
  },
  mainGridForgotPassword: {
    marginLeft: 0,
    width: "100%",
    height: "100%",
    marginTop: 0,
  },
  forgotPasswordMainContainer: {
    "@media (max-width:899px)": {
      overflow: "visible",
      padding: "34px",
      height: "100%",
    },
    margin: "34px",
    overflow: "hidden",
    height: "100%",
  },
};
// Customizable Area End
