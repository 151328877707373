export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const imgArrowForward = require("../assets/image_arrow_forward.png");
export const imgClose = require("../assets/image_close.png");
export const imgCheckmark = require("../assets/image_check_mark.png");
export const imgThreeDot = require("../assets/image_three_dot.png");
export const imgCheck = require("../assets/image_check.png");
export const imgCancel = require("../assets/image_cancle.png");
export const imgCard = require("../assets/image_card.png");
export const imgOk = require("../assets/image_ok.png");