Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.rorem = "Rorem Ipsum";
exports.httpGetMethod = "GET";
exports.getTermsdataEndPoint = "bx_block_terms_and_conditions/terms_and_conditions/";
exports.zipcodeText = "Enter Zip Code";
exports.cptcodeText = "Enter a CPT Code or Keyword";
exports.zipCodePlaceholder = "Enter ZIP Code or City , State";
exports.cptCodePlaceholder = "CPT Code";
exports.continueBtnText = "Continue";
exports.checkCptCode = "Check CPT Code";
exports.tooltipText = `The code used to identify this service is called a "CPT code." For the most accurate estimate, please ask your doctor for the CPT code or locate it on your bill.`;
exports.zipCodeError = "Zip Code must be exactly 5 digits";
exports.zipCodeRequiredError = "ZIP code is required";
exports.cptCodeRequiredError = "CPT code is required";
exports.costRelatedTo = "Cost of Care Related to";
exports.getCPTCodedataEndPoint = "bx_block_custom_form/cpt_codes/search_cpt_code";
exports.getCPTCodeEndPoint = "bx_block_custom_form/cpt_codes/cptcode_average_percentage";
exports.fiftyPercentile = "50th percentiles";
exports.getSimmiliarCptCodeDataEndPoint = "bx_block_custom_form/cpt_codes/similar_cpt_code";
exports.getPlanDataEndPoint = "bx_block_plan/plans";
exports.switchApiEndPoint = "bx_block_plan/plans/switch_plan";
exports.getAddressAndCardDetailsEndPoint = "bx_block_settings/settings/get_stripe_detail";
exports.updateCardAndAddressDetailsEndPoint = "bx_block_settings/settings/update_stripe_detail";
exports.addCardDetailsEndPoint = "bx_block_settings/settings/create_stripe_detail";
exports.okay = "Okay";
exports.publishment = "pk_test_51PRC7a02h0HQpyC0en3A953qYtevw7gxNcFlUeipDkE1JtBHjom8K2izTgU5DF0mVTmzRS4S551IRgo7i1pOW4Xc00Fl9PFB7n";
exports.stripePaymentEndPoint = "bx_block_stripe_integration/payment_intents/create_checkout_session";
exports.getRemainingCountOfPlan = "bx_block_stripe_integration/payment_intents/cpt_count";
exports.cancleCurrentPlan = "bx_block_stripe_integration/payment_intents/cancel_subscription";
exports.freePlanPurchaseAgain = "bx_block_plan/plans/active_free_plan";


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End