import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";

interface SignUpValues{
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  phoneNo: string;
  acceptCondition:boolean;
}

interface ErrorResponse {
  errors: { [key: string]: string };
}

interface SuccessResponse {
  message: string;
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      type: string | null;
      zip_code: number;
    };
    meta: {
      token: string;
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailError: string;
  phoneNumberError: string;
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  phoneNo: string;
  acceptCondition: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AccountRegistrationControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailRegistrationCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      emailError: "",
      phoneNumberError: "",
      firstName: "",
      lastName: "",
      email: "",
      zipCode: "",
      phoneNo: "",
      acceptCondition: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        responseJson.errors &&
        apiRequestCallId === this.apiEmailRegistrationCallId
      ) {
        this.handleSignUpError(responseJson);
      }

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.apiEmailRegistrationCallId
      ) {
        this.handleSignUpSuccess();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkSignUpDataFromStorage();
    return Promise.resolve();
  }

  checkSignUpDataFromStorage = async () => {
    const userData = await getStorageData("signUpData",true);
    if (userData) {
      this.setState({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        zipCode: userData.zipCode,
        phoneNo: userData.phoneNo,
        acceptCondition: userData.acceptCondition,
      });
    }
    removeStorageData("signUpData");
  }

  handleSubmit = (values: SignUpValues) => {
    this.handleSignUp(values);
  };

  handleSignUp = (values: SignUpValues) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const attrs = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNo,
      zip_code: values.zipCode,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailRegistrationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSignUpError = (responseJson: ErrorResponse) => {
    const { errors } = responseJson;
    let emailError = "";
    let phoneNumberError = "";
    if (errors.email && errors.email.length > 0) {
      emailError = "Email already taken";
    }
    if (errors.account && errors.account.length > 0) {
      emailError = "Email already taken";
    }
    if (errors.phone_number && errors.phone_number.length > 0 && errors.phone_number.includes("has already been taken")) {
      phoneNumberError = "Phone number already taken";
    }
    if (errors.phone_number && errors.phone_number.length > 0 && errors.phone_number.includes("Invalid phone number format")) {
      phoneNumberError = "Invalid Mobile No.";
    }
    this.setState({
      emailError,
      phoneNumberError,
    });
  };
  

  handleSignUpSuccess = () => {
    toast.success("Account created, setup a password using the email shared to finish setup")
    this.setState({ emailError:"", phoneNumberError:"" })
  };

  handleLoginNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationToPrivacy = (values : SignUpValues) => {
    setStorageData("signUpData", JSON.stringify(values));
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PrivacyPolicy");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationToTermsAndConditions = (values : SignUpValues) => {
    setStorageData("signUpData", JSON.stringify(values));
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
