import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import {
  imgClose,
  imgDoubleQuote,
  imgEarth,
  imgFacebook,
  imgGroup,
  imgInstagram,
  imgLandingPage,
  imgLinkdln,
  imgLocation,
  imgLock,
  imgLogo,
  imgMail,
  imgPhone,
  imgRight,
  imgStar,
  imgTwitter,
  imgVideo,
  imgYoutube,
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import LandingPageControllerWeb, {
  Props,
  configJSON,
} from "./LandingPageController.web";

export default class LandingPage extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: true,
  };

  buyFeatureComponent = () => (
    <Box sx={styles.feature}>
      <img src={imgRight} width={"16px"} height={"16px"} />
      <Typography sx={styles.featureText}>Dorem ipsum dolor</Typography>
    </Box>
  );

  freeFeatureComponent = () => (
    <Box sx={styles.feature}>
      <img src={imgClose} width={"16px"} height={"16px"} />
      <Typography sx={styles.featureText}>Vorem ipsum dolor</Typography>
    </Box>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.landingPageMain}>
          <Box sx={styles.landingPageInner}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Typography variant="h6" sx={styles.takeControl}>
                  Take Control of Your Healthcare Costs
                </Typography>
                <Typography variant="h6" sx={styles.takeControlUnder}>
                  Lorem ipsum dolor sit amet consectetur adipiscing
                  <br />
                  eli mattis sit phasellus mollis sit aliquam sit nullam
                  <br />
                  neque ultrices.
                </Typography>
                <Button
                  data-test-id="code-button"
                  variant="contained"
                  sx={styles.codeButton}
                  endIcon={<ArrowForwardIcon />}
                  onClick={this.cptClickHandler}
                >
                  {configJSON.checkCptCode}
                </Button>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12} sx={styles.imgGrid}>
                <Box sx={styles.certified}>
                  <img src={imgStar} />
                  <Typography variant="h6" sx={styles.officiallyCertified}>
                    Officially Certified
                  </Typography>
                </Box>
                <Box sx={styles.lock}>
                  <img src={imgLock} />
                </Box>
                <Box sx={styles.earth}>
                  <img src={imgEarth} />
                </Box>
                <Box sx={styles.doubleQuote}>
                  <Box sx={styles.imageDoubleQuote}>
                    <img src={imgDoubleQuote} />
                  </Box>
                  <Typography variant="h6" sx={styles.doubleQuoteText}>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit
                  </Typography>
                </Box>
                <img src={imgLandingPage} style={styles.imgLandingPage} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={styles.landingPage}>
          <Typography variant="h6" sx={styles.practiceAdvice}>
            Practice Advice
          </Typography>
          <Grid container spacing={8}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h6" sx={styles.reasonTitle}>
                Some Reasons why we are better than others
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h6" sx={styles.reasonText}>
                Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit aliquam sit nullam neque ultrices.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6} marginTop={"30px"}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card sx={styles.card}>
                <CardContent>
                  <div style={styles.cardIcon} />
                  <Typography variant="h6" sx={styles.cardTitle}>
                    Rorem Ipsum
                  </Typography>
                  <Typography variant="h6" sx={styles.cardDescription}>
                    Torem ipsum dolor sit amet,
                    <br />
                    consectetur adipiscing elit.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card sx={styles.card}>
                <CardContent>
                  <div style={styles.cardIcon} />
                  <Typography variant="h6" sx={styles.cardTitle}>
                    Forem Ipsum
                  </Typography>
                  <Typography variant="h6" sx={styles.cardDescription}>
                    Korem ipsum dolor sit amet,
                    <br />
                    consectetur adipiscing elit.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card sx={styles.card}>
                <CardContent>
                  <div style={styles.cardIcon} />
                  <Typography variant="h6" sx={styles.cardTitle}>
                    Sorem Ipsum
                  </Typography>
                  <Typography variant="h6" sx={styles.cardDescription}>
                    Morem ipsum dolor sit amet,
                    <br />
                    consectetur adipiscing elit.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={8} marginTop={"30px"}>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Typography variant="h6" sx={styles.sectionTitle}>
                Torem ipsum dolor sit amet, consectetur adipiscing elit.
              </Typography>
              <Typography variant="h6" sx={styles.sectionText}>
                Horem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit aliquam sit nullam neque ultrices.
              </Typography>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <img src={imgGroup} width={"100%"} height={"100%"} />
            </Grid>
          </Grid>
          <Box sx={styles.pricingContainer}>
            <Box sx={styles.pricingTextCenter}>
              <Typography variant="h6" sx={styles.pricingTitle}>
                Pricing
              </Typography>
              <Typography variant="h6" sx={styles.pricingSubtitle}>
                Affordable pricing plans
              </Typography>
              <Typography variant="h6" sx={styles.pricingDescription}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu
                egestas morbi sem vulputate etiam facilisis pellentesque ut
                quis.
              </Typography>
            </Box>
            <Box sx={styles.pricingBox}>
              <Box sx={styles.pricingPlanBox}>
                <Box sx={styles.pricingPlanInnerBox}>
                  <Typography variant="h6" sx={styles.planTitle}>
                    Free
                  </Typography>
                  <Box sx={styles.planPriceContainer}>
                    <Typography sx={styles.planPrice}>$0</Typography>
                    <Typography sx={styles.planPriceUnit}>/month</Typography>
                  </Box>
                  <Box sx={styles.planFeatures}>
                    <Box sx={styles.feature}>
                      <img src={imgRight} width={"16px"} height={"16px"} />
                      <Typography sx={styles.featureText}>
                        Dorem ipsum dolor
                      </Typography>
                    </Box>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div key={index}>{this.freeFeatureComponent()}</div>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.buttonContainer}>
                  <Button variant="contained" sx={styles.buyButton}>
                    Buy
                  </Button>
                </Box>
              </Box>
              <Divider orientation={"vertical"} flexItem />
              <Box sx={styles.pricingPlanBox}>
                <Box sx={styles.pricingPlanInnerBox}>
                  <Typography variant="h6" sx={styles.planTitle}>
                    Premium
                  </Typography>
                  <Box sx={styles.planPriceContainer}>
                    <Typography sx={styles.planPrice}>$699</Typography>
                    <Typography sx={styles.planPriceUnit}>/month</Typography>
                  </Box>
                  <Box sx={styles.planFeatures}>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div key={index}>{this.buyFeatureComponent()}</div>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.buttonContainer}>
                  <Button variant="contained" sx={styles.buyButton}>
                    Buy
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.videoContainer}>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <img src={imgVideo} style={styles.video} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={styles.watchButtonContainer}>
            <Button variant="contained" sx={styles.watchButton}>
              Watch Playlist
            </Button>
          </Box>
        </Box>
        <Box sx={styles.landingPageFooter}>
          <Box sx={styles.footerMainBox}>
            <Grid container spacing={8}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Box sx={styles.footerLogo}>
                  <img src={imgLogo} />
                  <Typography variant="h6" sx={styles.footerLogoText}>
                    Capital Claims
                  </Typography>
                </Box>
                <Typography variant="h6" sx={styles.pricingDescription}>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
                  mauris sed ma
                </Typography>
                <Box sx={styles.socialMedia}>
                  <img src={imgFacebook} />
                  <img src={imgInstagram} />
                  <img src={imgLinkdln} />
                  <img src={imgYoutube} />
                  <img src={imgTwitter} />
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" sx={styles.footerHeader}>
                      Product
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Features
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Pricing
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Reviews
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Updates
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" sx={styles.footerHeader}>
                      Company
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        About
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Contact US
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Careers
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Culture
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" sx={styles.footerHeader}>
                      Support
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Getting started
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Help Center
                      </Typography>
                    </Typography>
                    <Typography variant="body2" sx={styles.anchorTagGrid}>
                      <Typography
                        component="a"
                        href="#"
                        style={styles.anchorTagText}
                      >
                        Chat Support
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" sx={styles.footerHeader}>
                      Get In Touch
                    </Typography>
                    <Box sx={styles.footerInfo}>
                      <img src={imgPhone} />
                      <Typography variant="body2" sx={styles.footerInfoText}>
                        (450) 555-555
                      </Typography>
                    </Box>
                    <Box sx={styles.footerInfo}>
                      <img src={imgLocation} />
                      <Typography variant="body2" sx={styles.footerInfoText}>
                        2118 Thornridge Cir. Syracuse, Connecticut 35624
                      </Typography>
                    </Box>
                    <Box sx={styles.footerInfo}>
                      <img src={imgMail} />
                      <Typography variant="body2" sx={styles.footerInfoText}>
                        capitalclaims@vyz.us
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={styles.footerTextBox}>
            <Typography variant="h6" sx={styles.footerText}>
              Copyright © 2024 Capital Claims | All Rights Reserved |{" "}
              <Typography component="a" href="#" style={styles.anchorTag}>
                Terms and Conditions
              </Typography>{" "}
              |{" "}
              <Typography component="a" href="#" style={styles.anchorTag}>
                Privacy Policy
              </Typography>
            </Typography>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  landingPage: {
    marginTop: "30px",
    padding: "80px",
    "@media (max-width:600px)": {
      padding: "40px",
    },
  },
  landingPageMain: {
    backgroundColor: "#11294B",
    padding: "60px 80px 0",
    "@media (max-width:600px)": {
      padding: "40px 40px 0",
    },
  },
  landingPageInner: {
    "@media (max-width:600px)": {
      marginTop: "15px",
    },
  },
  takeControl: {
    fontFamily: "Tiempos Headline",
    paddingBottom: "20px",
    fontWeight: 700,
    fontSize: "64px",
    lineHeight: "76.8px",
    color: "#FFFFFF",
  },
  takeControlUnder: {
    paddingBottom: "20px",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#CACACA",
  },
  codeButton: {
    backgroundColor: "#1799CB",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "8px",
    fontSize: "16px",
  },
  imgGrid: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width:1199px)": {
      marginTop: "50px",
    },
  },
  certified: {
    position: "absolute",
    padding: "5px",
    top: "47%",
    right: "66%",
    width: "230px",
    height: "59px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    transition: "all 0.3s ease",
  },
  doubleQuote: {
    position: "absolute",
    top: "67%",
    right: "2%",
    padding: "10px",
    width: "286px",
    height: "106px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    transition: "all 0.3s ease",
  },
  imageDoubleQuote: {
    position: "absolute",
    right: "92%",
    top: "-37%",
  },
  doubleQuoteText: {
    fontSize: "18px",
    lineHeight: "30px",
    fontWeight: 400,
    color: "#282828",
  },
  lock: {
    position: "absolute",
    top: "0%",
    right: "59%",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
  },
  earth: {
    position: "absolute",
    top: "23%",
    right: "11%",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
  },
  officiallyCertified: {
    marginLeft: "5px",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 500,
    color: "#2E2D2D",
  },
  imgLandingPage: {
    maxWidth: "100%",
    height: "auto",
  },
  practiceAdvice: {
    paddingBottom: "10px",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#1799CB",
  },
  reasonTitle: {
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "57.6px",
    color: "#2E2D2D",
  },
  reasonText: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#424242",
  },
  card: {
    textAlign: "center",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  cardIcon: {
    margin: "0 auto",
    marginTop: 16,
    width: 70,
    height: 70,
    backgroundColor: "#2196F3",
    borderRadius: "50%",
  },
  cardTitle: {
    marginTop: 6,
    fontWeight: "bold",
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27.9px",
    color: "#424242",
    marginTop: "16px",
    marginBottom: "32px",
  },
  sectionTitle: {
    marginTop: "55px",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "57.6px",
    color: "#2E2D2D",
  },
  sectionText: {
    paddingTop: "10px",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27.9px",
    color: "#424242",
  },
  pricingContainer: {
    marginTop: "94px",
    borderRadius: "28px",
    padding: "79px 0",
    backgroundColor: "#ECFAFF",
  },
  pricingTextCenter: {
    textAlign: "center",
  },
  pricingTitle: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1799CB",
  },
  pricingSubtitle: {
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "57.6px",
    color: "#2E2D2D",
    paddingTop: "10px",
  },
  pricingDescription: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "30px",
    color: "#9795B5",
    paddingTop: "10px",
  },
  pricingBox: {
    padding: "96px 0 0",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 751px)": {
      padding: "46px 0 0",
      display: "block",
    },
  },
  pricingPlanBox: {
    padding: "0 110px",
    "@media (max-width: 1092px) and (min-width: 872px)": {
      padding: "0 55px",
    },
    "@media (max-width: 871px) and (min-width: 752px)": {
      padding: "0 25px",
    },
    "@media (max-width: 751px) and (min-width: 621px)": {
      padding: "20px 105px",
    },
    "@media (max-width: 621px)": {
      padding: "20px 30px",
    },
    "@media (max-width: 374px)": {
      padding: "20px 20px",
    },
  },
  pricingPlanInnerBox: {
    padding: "0 40px",
    textAlign: "center",
  },
  planTitle: {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#1E293B",
  },
  planPriceContainer: {
    display: "flex",
    alignitems: "center",
    justifyContent: "center",
    paddingTop: "15px",
  },
  planPrice: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    color: "#334155",
  },
  planPriceUnit: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1E293B",
    marginTop: "18px",
  },
  planFeatures: {
    paddingTop: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  feature: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "5px",
  },
  featureText: {
    marginLeft: "5px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#9795B5",
  },
  buttonContainer: {
    paddingTop: "15px",
  },
  buyButton: {
    backgroundColor: "#1799CB",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "8px",
    fontSize: "16px",
    width: "100%",
  },
  videoContainer: {
    marginTop: "94px",
    padding: "0 110px",
    "@media (max-width:992px)": {
      padding: 0,
    },
  },
  video: {
    width: "100%",
    height: "auto",
  },
  watchButtonContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watchButton: {
    backgroundColor: "#1799CB",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "8px",
    fontSize: "16px",
    width: "254px",
  },
  landingPageFooter: {
    backgroundColor: "#ECFAFF",
    padding: "100px 100px 0",
  },
  footerMainBox: {
    paddingBottom: "100px",
  },
  footerLogo: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },
  footerLogoText: {
    fontFamily: "Tiempos Headline",
    marginLeft: "5px",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "36px",
    width: "244px",
    marginTop: "20px",
  },
  footerHeader: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "20px",
    color: "#5D5A88",
    paddingBottom: "30px",
  },
  footerTextBox: {
    textAlign: "center",
    padding: "32px 0",
  },
  footerText: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#767494",
  },
  anchorTag: {
    color: "#ADABC3",
  },
  anchorTagText: {
    color: "#ADABC3",
    textDecoration: "none",
  },
  anchorTagGrid: {
    color: "#ADABC3",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "16px",
    paddingBottom: "10px",
    textTransform: "none",
  },
  footerInfo: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
  },
  footerInfoText: {
    color: "#ADABC3",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "16px",
    paddingBottom: "10px",
    textTransform: "none",
    marginLeft: "8px",
  },
};
// Customizable Area End
